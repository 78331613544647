import { ChevronLeftOutlined } from "@mui/icons-material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, Breadcrumbs, IconButton, Link, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom'

type Props = {
  pageTitle: string;
  children?: ReactNode;
  canGoBack?: boolean;
  breadcrumbs: {
    label: string;
    href?: string;
  }[];
  chip?: ReactNode;
}

function DashboardLayoutHeader({ pageTitle, children, breadcrumbs, canGoBack = false, chip }: Props) {
  const navigate = useNavigate()
  
  return (
    <Stack direction="row" justifyContent="space-between" mb={'24px'}>
      <Stack gap='16px'>
        <Stack direction="row" gap="8px" alignItems="center">
          {canGoBack && (
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeftOutlined sx={{ color: 'black' }} />
            </IconButton>
          )}
          <Typography fontSize={'24px'} color="black" fontWeight={700}>{pageTitle}</Typography>
          {chip}
        </Stack>
        <Breadcrumbs separator={<Box width="3px" height="3px" borderRadius="3px" bgcolor="black" />}>
          {breadcrumbs.map((i, ix) => {
            if (breadcrumbs.length - 1 === ix) return (
              <Typography key={ix} color="#999999">{i.label}</Typography>
            )

            return (
              <Link
                key={ix}
                component={RouterLink}
                underline="hover" 
                sx={{ ':hover': { cursor: 'pointer' } }} 
                color="black"
                to={i.href!}
              >
                {i.label}
              </Link>
            )
          })}
        </Breadcrumbs>
      </Stack>
      <Stack maxWidth="500px" direction={'row'} gap={'16px'}>
        {children}
      </Stack>
    </Stack>
  )
}

const ButtonAction = (props: LoadingButtonProps) => (
  <Box>
    <LoadingButton {...props} />
  </Box>
)

export default Object.assign(DashboardLayoutHeader, {
  Button: ButtonAction
})
