import { InputHTMLAttributes, ReactElement } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import {
  RichTextEditor,
  RichTextEditorProps,
} from "../app/component/RichTextEditor";
import { Article, FileOpen, PhotoLibrary } from "@mui/icons-material";

type TextProps = {
  element: "TEXT";
  props: TextFieldProps;
};
type VideoProps = {
  element: "VIDEO";
  props: InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
  };
};
type FileProps = {
  element: "FILE";
  props: InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
  };
};
type NumberProps = {
  element: "NUMBER";
  props: TextFieldProps;
};
type TextAreaProps = {
  element: "TEXT_AREA";
  props: TextFieldProps;
};
type RichTextProps = {
  element: "RICH_TEXT";
  props: RichTextEditorProps;
};
type ImageProps = {
  element: "IMAGE";
  props: {
    preview: string;
    label?: string;
    onOpenMedia: (name: string) => void;
  } & Partial<HTMLInputElement>;
};

type GetFieldProps = (TextProps | TextAreaProps | RichTextProps | ImageProps | VideoProps | FileProps | NumberProps) & { disabled?: boolean; };

export const getField = ({ element, props, disabled }: GetFieldProps) => {
  let result: ReactElement = <></>;

  if (element === "TEXT") {
    result = <TextField disabled={disabled} fullWidth {...props} />;
  } else if (element === "NUMBER") {
    result = <TextField disabled={disabled} fullWidth type="number" {...props} />;
  } else if (element === "TEXT_AREA") {
    result = <TextField disabled={disabled} fullWidth multiline rows={5} {...props} />;
  } else if (element === "RICH_TEXT") {
    result = <RichTextEditor disabled={disabled} {...props} />;
  // } else if (element === 'VIDEO') {
  //   result = (
  //     <Box onClick={() => (props as any).onOpenMedia(props.name!)}>
  //       <Typography color="#534e48" fontSize={11} ml={2}>
  //         {props.label}
  //       </Typography>
  //       {/* <input disabled={disabled} type="file" accept="video/mp4,video/x-m4v,video/*" {...props} /> */}
  //     </Box>
  //   )
  // } else if (element === 'FILE') {
  //   result = (
  //     <Box onClick={() => (props as any).onOpenMedia(props.name!)}>
  //       <Typography color="#534e48" fontSize={11} ml={2}>
  //         {props.label}
  //       </Typography>
  //       {/* <input disabled={disabled} type="file" accept="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf" {...props} /> */}
  //     </Box>
  //   )
  } else if (element === "IMAGE" || element === 'FILE' || element === 'VIDEO') {
    result = (
      <Box sx={{ width: "100%", height: (props as any).preview ? '200px' : "100px", marginBottom: 2 }}>
        <Typography color="#534e48" fontSize={11} ml={2}>
          {props.label}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: (props as any).preview ? '200px' : "100px",
            border: ({ palette }) => `1px solid ${palette.divider}`,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          disabled={disabled}
          component={(element === 'FILE' && !!(props as any).preview) ? Stack : Button}
          onClick={() => (element === 'FILE' && !!(props as any).preview) ? null : (props as any).onOpenMedia(props.name!)}
        >
          {element === 'IMAGE'
            ? props.preview
              ? <img style={{ objectFit: 'contain', width: '200px', height: '100%' }} alt="" src={props.preview} /> 
              : <Stack alignItems="center"><PhotoLibrary />Select Image</Stack> 
            : null
          }
          {element === 'FILE' 
            ? (props as any).preview
              ? (<Button startIcon={<Article />} sx={{ position: 'relative' }} onClick={() => window.open((props as any).preview)}>
              Preview file
            </Button>)
              : <Stack alignItems="center"><FileOpen />Select File</Stack> 
            : null
          }
          {element === 'VIDEO' 
            ? (props as any).preview
              ? <video style={{ objectFit: 'contain', width: '200px', height: '100%' }} src={(props as any).preview} /> 
              : <Stack alignItems="center"><FileOpen />Select Video</Stack> 
            : null
          }
          {(!!(props as any).preview && element === 'FILE') && (
            <Button variant="outlined" onClick={() => (props as any).onOpenMedia(props.name!)}>
              Change
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  return result;
};
