import { Box, Button, Stack } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";

export const PostPage = () => {
  const {
    accountStore: { account, getUser },
    commonStore,
  } = useStore();

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    commonStore.setTitlePage("Post");
  });
  
  return (
    <Box>
      <DashboardLayoutHeader
        pageTitle="Post"
        breadcrumbs={[{ label: "post", href: "/post" }]}
      >
        <Link to={'/post/form'}>
          <Button variant="contained">Add Post</Button>
        </Link>
      </DashboardLayoutHeader>
      <DataGrid
        autoHeight
        columns={[
          {
            field: 'title',
            headerName: 'Title'
          }
        ]}
        rows={[]}
      />
    </Box>
  )
}