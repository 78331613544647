import { Box, Button } from "@mui/material"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import PageApprovalDataGrid from "./PageApprovalDataGrid";
import { ApproveRejectModal } from "./ApproveRejectModal";
import { useState } from "react";
import { ApprovalResponseType } from "../../app/config/enum";
import { PageApproveRejectTypeValues } from "../../app/models/pageApproval";
import { Helmet } from "react-helmet";

export const PageApprovalPage = () => {
  const [approveRejectState, setApproveRejectState] = useState<PageApproveRejectTypeValues | null>(null)
  
  return (
    <Box>
      <Helmet>
        <title>Page Approval | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        pageTitle="Page Approval"
        breadcrumbs={[
          { label: 'pages', href: '/pages/approval' },
          { label: 'approval', href: '/pages/approval' },
        ]}
      >
      </DashboardLayoutHeader>
      <PageApprovalDataGrid setApproveRejectState={setApproveRejectState} />
      <ApproveRejectModal approveRejectState={approveRejectState} setApproveRejectState={setApproveRejectState} isOpen={!!approveRejectState} onClose={() => setApproveRejectState(null)} />
    </Box>
  )
}
