import { Box, Button } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import SitemapSortModal from "./SitemapSortModal";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { SitemapTree } from "./SitemapTree";
import SitemapModal from "./SitemapModal";

const SitemapPage = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)

  // useEffect(() => {
  //   getDropdownPage()
  //   getDropdownSitemap()
  // }, [getDropdownPage, getDropdownSitemap])
  
  return (
    <>
      <Helmet>
        <title>Sitemap | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        pageTitle="Sitemap"
        breadcrumbs={[{ label: "sitemap", href: "/sitemap" }]}
      >
        <Box>
          <Button onClick={() => setIsAddModalOpen(true)} variant="contained">
            Add Sitemap
          </Button>
        </Box>
      </DashboardLayoutHeader>
      <SitemapTree />
      <SitemapModal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
      <SitemapSortModal />
    </>
  );
};

export default observer(SitemapPage)