import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { IMedia } from "../../app/models/media";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import { getFileExtension } from "../../utils/getFileExtentions";
import { getFileNameWithoutExtension } from "../../utils/getFileNameWithoutExtention";

interface MediaImageProps {
  onClickMedia?: (media: IMedia) => void;
  onRemoveMedia?: (id: string) => void;
  onEditMedia?: (id: string, value: IMedia) => Promise<void>;
  editable?: boolean;
  croppable?: boolean;
}

export const MediaImage = observer(
  ({
    onClickMedia,
    onRemoveMedia,
    editable,
    onEditMedia,
    croppable,
  }: MediaImageProps) => {
    const { openMediaCropper } = useStore().mediaCropperStore;
    const {
      getImageGrid,
      imageGrid,
      imageGridLoading,
      imageGridParams,
      setImageParams,
      editMediaLoading,
    } = useStore().mediaStore;
    const totalPage = Math.ceil(imageGrid.rowCount / imageGridParams.pageSize);

    const [edited, setEdited] = useState<IMedia & { ext: string }>();

    useEffect(() => {
      getImageGrid();
    }, [getImageGrid]);

    return (
      <Box>
        <Grid container spacing={2}>
          {imageGridLoading ? (
            <Grid item xs={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            imageGrid.data.map((i) => (
              <Grid
                position="relative"
                overflow="hidden"
                item
                xs={3}
                key={i.id}
              >
                {edited?.id === i.id ? (
                  <Stack
                    direction="row"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      position: "absolute",
                      zIndex: 2,
                      top: 0,
                      right: 0,
                    }}
                  >
                    <IconButton
                      onClick={() => setEdited(undefined)}
                      size="small"
                      disabled={editMediaLoading}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        let payload = { ...edited };
                        payload.nameFile = payload.nameFile + "." + payload.ext;
                        delete (payload as any).ext;

                        onEditMedia &&
                          onEditMedia(i.id, payload).then(() =>
                            setEdited(undefined)
                          );
                      }}
                      size="small"
                      color="success"
                      disabled={editMediaLoading}
                    >
                      {editMediaLoading ? (
                        <CircularProgress size={14} />
                      ) : (
                        <Check fontSize="small" />
                      )}
                    </IconButton>
                  </Stack>
                ) : (
                  (!!onRemoveMedia || !!editable) && (
                    <Stack
                      direction="row"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: 2,
                        position: "absolute",
                        zIndex: 2,
                        top: 0,
                        right: 0,
                      }}
                    >
                      {!!editable && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            setEdited({
                              ...i,
                              ext: getFileExtension(i.nameFile) ?? "",
                              nameFile: getFileNameWithoutExtension(i.nameFile),
                            })
                          }
                        >
                          <Edit fontSize="small" color="info" />
                        </IconButton>
                      )}
                      {!!onRemoveMedia && (
                        <IconButton
                          size="small"
                          onClick={() => onRemoveMedia(i.id)}
                        >
                          <Delete fontSize="small" color="error" />
                        </IconButton>
                      )}
                    </Stack>
                  )
                )}
                <Box
                  // variant="outlined"
                  // color="inherit"
                  // onClick={() => onClickMedia && onClickMedia(i)}
                  sx={{
                    border: ({ palette }) => `1px solid ${palette.divider}`,
                    borderRadius: 1,
                    position: "relative",
                    width: "100%",
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ objectFit: "contain", height: 200 }}
                    alt=""
                    src={i.filePath}
                  />
                </Box>
                <Stack direction="row" gap={1}>
                  <TextField
                    sx={{ mb: 1 }}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        edited?.id === i.id ? "." + edited?.ext : "",
                    }}
                    disabled={edited?.id !== i.id}
                    value={edited?.id === i.id ? edited?.nameFile : i.nameFile}
                    size="small"
                    onChange={(e) =>
                      setEdited({ ...edited!, nameFile: e.target.value })
                    }
                  />
                  {!editable && (
                    <>
                      {croppable && (
                        <Button
                          onClick={() => openMediaCropper(i.filePath)}
                          size="small"
                          sx={{ height: "36px" }}
                          variant="outlined"
                        >
                          Crop
                        </Button>
                      )}
                      <Button
                        onClick={() => onClickMedia && onClickMedia(i)}
                        size="small"
                        sx={{ height: "36px" }}
                        variant="contained"
                      >
                        Pick
                      </Button>
                    </>
                  )}
                </Stack>
              </Grid>
            ))
          )}
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={5}>
          <Pagination
            onChange={(event, page) => setImageParams("currentPage", page - 1)}
            page={imageGridParams.currentPage + 1}
            count={!!totalPage ? totalPage : 1}
          />
        </Box>
      </Box>
    );
  }
);
