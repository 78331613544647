import { observer } from "mobx-react-lite";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import { getIcon } from "../../utils/getIcon";
import { TailwindProvider } from "../../app/component/TailwindProvider";
import { renderHtml } from "../../utils/renderHtml";

const ComponentDataGrid = () => {
  const { components, getComponents, componentsLoading } =
    useStore().componentStore;

  useEffect(() => {
    getComponents()
  }, [getComponents])

  return (
    <StyledDataGridPremium
      loading={componentsLoading}
      columns={[
        {
          field: "name",
          headerName: "Name",
          flex: 1,
        },
        {
          field: 'icon',
          headerName: 'Icon',
          renderCell: (params) => {
            const Icon = getIcon(params.row.icon)
            return <Icon />
          }
        },
        // {
        //   field: 'html',
        //   headerName: 'Preview',
        //   renderCell: (params) => (
        //     <TailwindProvider>
        //       {renderHtml(params.row.html)}
        //     </TailwindProvider>
        //   )
        // },
        // {
        //   field: 'htmlSkeleton',
        //   headerName: 'Skeleton',
        //   renderCell: (params) => (
        //     <TailwindProvider>
        //       {renderHtml(params.row.htmlSkeleton)}
        //     </TailwindProvider>
        //   )
        // }
      ]}
      rows={components}
      autoHeight
    />
  );
};

export default observer(ComponentDataGrid);
