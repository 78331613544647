import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";

interface SelectTemplateProps {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
}

const SelectTemplate = ({ onChange, value, disabled }: SelectTemplateProps) => {
  const { getTemplate, template } = useStore().templateStore
  
  useEffect(() => {
    getTemplate()
  }, [getTemplate])
  
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value)
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
      <Select
        disabled={disabled}
        labelId="template"
        id="template"
        label="Select Template"
        onChange={handleChange}
        value={value}
      >
        {template.map((option, index) => (
          <MenuItem value={option.id} key={index}>{option.name}</MenuItem>
        ))}
        {/* <MenuItem value="00000000-0000-0000-0000-000000000000"></MenuItem> */}
      </Select>
    </FormControl>
  )
}

export default observer(SelectTemplate)