import { makeAutoObservable } from "mobx";
import { ReactCropperElement } from "react-cropper";
import { MediaStore } from "./mediaStore";
import { MediaType } from "../config/enum";

function getMimeTypeFromBase64(base64String: string) {
  // Check if the base64 string contains the data URL scheme
  const dataUrlPattern = /^data:(.*?);base64,/;
  const matches = base64String.match(dataUrlPattern);

  // If the base64 string contains the data URL scheme, extract the MIME type
  if (matches) {
    return matches[1]; // Return the MIME type
  } else {
    // If not, return a default MIME type or handle the error
    return null; // Or a default MIME type like 'application/octet-stream'
  }
}

function getImageExtensionFromMimeType(mimeType: any) {
  const imageMimeTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'image/tiff': 'tif',
    'image/x-icon': 'ico'
  };

  return (imageMimeTypes as any)[mimeType] || 'png';
}

export class MediaCropperStore {
  mediaUri: string | null = null
  mediaStore = new MediaStore()
  
  cropLoading = false
  
  constructor() {
    makeAutoObservable(this)
  }

  openMediaCropper = (uri: string) => {
    this.mediaUri = uri;
  }

  closeMediaCropper = () => {
    this.mediaUri = null
  }
  
  cropMedia = async (ref: ReactCropperElement | null) => {
    if (!ref) return;
    
    this.cropLoading = true;
    
    try {
      const time = new Date().getTime()
      
      const base64 = ref.cropper.getCroppedCanvas().toDataURL()
      const base64Data = base64.includes(',') ? base64.split(',')[1] : base64;
  
      const mimeType = getMimeTypeFromBase64(base64) ?? ''
      const binaryString = atob(base64Data)
      
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }
      const ext = getImageExtensionFromMimeType(mimeType)
      
      // Create a Blob object from byte array
      const blob = new Blob([byteArray], { type: mimeType });
      
      const formData = new FormData()
      
      formData.append('File', blob)
      formData.append('FileName', `Crop-${time}.${ext}`)
      formData.append('Type', MediaType.IMAGE)

      return {
        formData,
        file: blob
      }
    } finally {
      this.cropLoading = false
    }
  }

  clearMediaUri = () => {
    this.mediaUri = null;
  }
}