import { Stack, Typography } from "@mui/material";
import { IComponent } from "../../../app/models/component";
import { DraggableContainerItem } from "./DraggableContainerItem";

interface DraggableProps {
  items: IComponent[]
}

export const DraggableContainer = ({ items }: DraggableProps) => {
  const groupedData = items.reduce((acc, curr: any) => {
    const category = curr.category || "Other"; 
    if (!(acc as any)[category]) {
      (acc as any)[category] = [];
    }
    (acc as any)[category].push(curr);
    return acc;
  }, {});

  const labels = Object.keys(groupedData)

  return (
    <Stack gap={.5} p={2}>
      {labels.map((label, index) => {
        return (
          <Stack key={index}>
            <Typography mt={2} mb={1} fontWeight={700} color="black" fontSize={'16px'}>{label}</Typography>
            {(groupedData as any)[label].map((item: any, x: any) => (
              <DraggableContainerItem key={x} item={item} />
            ))}
          </Stack>
        )
      })}
    </Stack>
  );
};
