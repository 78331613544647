import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useFormik } from "formik";
import * as yup from "yup";

interface PageUnpublishModalProps {
  pageId: string
}

const PageUnpublishModal = ({ pageId }: PageUnpublishModalProps) => {
  const {
    pagePublishStore,
    modalStore
  } = useStore();
  const {
    unpublish, unpublishLoading,
    getDetail,
    getGrid,
  } = pagePublishStore;

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormik({
    validationSchema: yup.object({
      notes: yup.string(),
    }),
    initialValues: {
      notes: ''
    },
    onSubmit: (values) => {
      unpublish(pageId, values).then(() => {
        modalStore.close();
        resetForm();
        getDetail(pageId);
        getGrid()
      });
    },
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{ maxWidth: 500, width: "100%" }}
    >
      <form onSubmit={handleSubmit} noValidate>
        <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
          Unpublish page
        </Typography>
        <Box mb={2}>
          <Stack sx={{ pt: 2, gap: 1 }}>
            <TextField
              name="notes"
              required
              value={values.notes}
              onChange={handleChange}
              error={!!errors.notes}
              helperText={errors.notes}
              label="Notes"
              rows={5}
              multiline
            />
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            type="button"
            onClick={() => {
              modalStore.close();
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};

export default observer(PageUnpublishModal);
