import { Box } from "@mui/material"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import PagePublishDataGrid from "./PagePublishDataGrid"
import { Helmet } from "react-helmet"

export const PagePublishPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Publish | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        breadcrumbs={[
          { label: 'pages', href: '/pages/publish' },
          { label: 'publish', href: '/pages/publish' },
        ]}
        pageTitle="Publish page"
      />
      <PagePublishDataGrid />
    </>
  )
}