import { makeAutoObservable, reaction, toJS } from "mobx";
import { IDndTemplate } from "../models/dndTemplate";

export class DndTemplateStore {
  dndItems: IDndTemplate[] = []
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.dndItems, (val) => console.log(toJS(val)))
  }

  setDndItems = (items: IDndTemplate[]) => {
    this.dndItems = items;
  }

  addDndItem = (item: IDndTemplate, index?: number) => {
    this.dndItems = [...this.dndItems, item]
  }

  removeDndItem = (itemId: string) => {
    this.dndItems = this.dndItems.filter(i => i.itemId !== itemId)
  }

  resetDnd = () => {
    this.dndItems = []
  }
}