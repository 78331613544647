import { useEffect, useMemo, useState } from "react"
import { useStore } from "../../app/stores/store"
import Chart from 'react-apexcharts'
import { observer } from "mobx-react-lite"
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, Toolbar } from "@mui/material"

export const ContentTypeChart = observer(() => {
  const { getDashboardContentType, dashboardContentTypeLoading, dashboardContentType } = useStore().dashboardStore
  const [selected, setSelected] = useState<string>('')
  
  const labels = useMemo(() => {
    return (!!dashboardContentType || selected) ? Object.keys(dashboardContentType.find(f => f.name === selected!)?.total ?? {}) : []
  }, [dashboardContentType, selected])
  
  const data = useMemo(() => {
    let temp: number[] = [];
    
    if (labels && dashboardContentType.find(f => f.name === selected!)?.total) {
      labels?.forEach((label, index) => {
        temp.push((dashboardContentType.find(f => f.name === selected!)?.total ?? {} as any)[label])
      })
    }

    return temp
  }, [dashboardContentType, labels, selected])

  const selectList = useMemo(() => dashboardContentType.map(i => ({ label: i.name, value: i.name })), [dashboardContentType])
  
  useEffect(() => {
    selectList && Array.isArray(selectList) && setSelected(selectList[0]?.value ?? '')
  }, [selectList])
  
  useEffect(() => {
    getDashboardContentType()
  }, [getDashboardContentType])
  
  if (dashboardContentTypeLoading) return <Box height={'200px'} justifyContent="center" alignItems="center" display="flex"><CircularProgress /></Box>
  
  return (
    <Stack gap={2}>
      <Toolbar sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}>
        <FormControl sx={{ maxWidth: '300px', width: '100%' }}>
          <InputLabel size="small" id="demo-simple-select-label">Category</InputLabel>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            displayEmpty
            label="Category"
            onChange={(v) => setSelected(v.target.value)}
          >
            {selectList.map((i, x) => (
              <MenuItem key={x} value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Toolbar>
      <Chart
        options={{
          chart: {
            width: '100%',
            type: 'pie',
          },
          labels: labels,
          theme: {
            monochrome: {
              enabled: true
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          },
          title: {
            text: "Content Type"
          },
        }}
        series={data ?? []}
        type="pie"
        width="500"
      />
    </Stack>
  )
})