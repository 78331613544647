import { CloseRounded } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material"

interface ViewImageModalProps {
  isOpen: boolean;
  onCLose: () => void;
  url: string | null;
}

export const ViewImageModal = ({ isOpen, onCLose, url }: ViewImageModalProps) => {
  return (
    <Modal component="div" open={isOpen} onClose={onCLose}>
      <>
        <IconButton sx={{ position: 'absolute', top: 20, right: 20 }} onClick={onCLose}>
          <CloseRounded sx={{ color: 'white '}} />
        </IconButton>
        <Box width={'100vw'} height='100vh' display="flex" justifyContent="center" alignItems="center">
          {url ? <img style={{ objectFit: 'contain', height: '100vh' }} src={url} alt="" /> : <></>}
        </Box>
      </>
    </Modal>
  )
}