import { Box, CircularProgress, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { PagePublishDetailForm } from "./PagePublishDetailForm";
import { PublishStatus } from "../../app/config/enum";
import { IPagePublishPayload } from "../../app/models/pagePublish";
import { history } from "../..";

const PagePublishDetailPage = () => {
  const { getDetail, detail, detailLoading, getGrid, publish, publishLoading } = useStore().pagePublishStore;
  const { pageId } = useParams();
  const [schedule, setSchedule] = useState<Date | null>(null);

  const publishPage = async () => {
    let payload, status

    if (schedule) {
      status = PublishStatus.ON_SCHEDULLED 
      payload = { pageId: pageId!, schedullerTime: schedule }
    } else {
      status = PublishStatus.PUBLISHED
      payload = { pageId: pageId! }
    }
    
    await publish(status, payload as IPagePublishPayload)
      .then(res => {
        history.push('/pages/publish')
        getGrid()
      })
  }
  
  useEffect(() => {
    if (!pageId) return;

    getDetail(pageId);
  }, [getDetail, pageId]);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "page",
            href: "/pages/publish",
          },
          {
            label: "publish",
            href: "/pages/publish",
          },
          {
            label: detail?.pageName ?? "...",
            href: "/pages/publish/" + pageId,
          },
        ]}
        pageTitle={"Publish " + (detail?.pageName ?? '...') + ' Page'}
        canGoBack
      >
        <DashboardLayoutHeader.Button loading={publishLoading} onClick={publishPage} variant="contained">
          Publish
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      {detailLoading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        detail && (
          <PagePublishDetailForm
            setSchedule={setSchedule}
            schedule={schedule}
            detail={detail}
          />
        )
      )}
    </Box>
  );
};

export default observer(PagePublishDetailPage);
