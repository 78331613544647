import { Box } from "@mui/material";
import { ContentTypeName, Roles } from "./enum";
import { People, WidgetsRounded, SpaceDashboardRounded, HomeRounded, ArticleRounded, FolderRounded, AccountTreeRounded, PermMediaRounded } from "@mui/icons-material";
import agent from "../api/agent";

export interface Menu {
  title: string;
  icon?: JSX.Element;
  route?: string;
  roles?: Roles[];
  submenu?: Array<Menu>;
}

export const SubIcon = () => (
  <Box sx={{ width: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Box width={"9px"} height={"9px"} borderRadius={"9px"} bgcolor={"white"} />
  </Box>
);

export const menus: Array<Menu> = [
  {
    title: "Dashboard",
    icon: <HomeRounded fontSize="small" />,
    route: "/",
    roles: [Roles.Approver, Roles.Creator, Roles.Publisher, Roles.Admin],
  },
  {
    title: "Templates",
    icon: <SpaceDashboardRounded fontSize="small" />,
    route: "/templates",
    roles: [Roles.Creator, Roles.Admin],
  },
  {
    title: "Page",
    icon: <ArticleRounded fontSize="small" />,
    roles: [Roles.Approver, Roles.Creator, Roles.Publisher, Roles.Admin],
    submenu: [
      {
        title: "List",
        icon: <SubIcon />,
        route: "/pages/list",
        roles: [Roles.Approver, Roles.Creator, Roles.Publisher, Roles.Admin]
      },
      {
        title: "Approval",
        icon: <SubIcon />,
        route: "/pages/approval",
        roles: [Roles.Approver]
      },
      {
        title: "Publish",
        icon: <SubIcon />,
        route: "/pages/publish",
        roles: [Roles.Publisher]
      }
    ]
  },
  {
    title: 'Sitemap',
    icon: <AccountTreeRounded />,
    route: '/sitemap',
    roles: [Roles.Creator, Roles.Admin],
  },
  {
    title: "Content Types",
    icon: <FolderRounded fontSize="small" />,
    roles: [Roles.Approver, Roles.Creator, Roles.Publisher, Roles.Admin],
    submenu: [
      {
        title: 'List',
        icon: <SubIcon />,
        roles: [Roles.Approver, Roles.Creator, Roles.Publisher, Roles.Admin],
      },
      {
        title: 'Category',
        icon: <SubIcon />,
        route: '/content-types/category/list'
      },
      {
        title: 'Approval',
        icon: <SubIcon />,
        route: '/content-types/approval',
        roles: [Roles.Approver]
      },
      {
        title: 'Publish',
        icon: <SubIcon />,
        route: '/content-types/publish',
        roles: [Roles.Publisher]
      }
    ],
  },
  {
    title: "Components",
    icon: <WidgetsRounded fontSize="small" />,
    roles: [Roles.Creator, Roles.Admin],
    submenu: [
      {
        title: "List",
        icon: <SubIcon />,
        route: "/components",
      },
      {
        title: "Timeline",
        icon: <SubIcon />,
        route: "/components/timeline",
      },
    ]
  },
  {
    title: 'Media',
    icon: <PermMediaRounded />,
    route: '/medias',
    roles: [Roles.Creator, Roles.Admin],
  },
  {
    title: "User Management",
    icon: <People fontSize="small" />,
    route: "/users",
  },
];
