import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useFormik } from "formik";
import * as yup from "yup";

function moveElement(array: any[], fromIndex: number, toIndex: number) {
  if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
    console.error('Indeks tidak valid');
    return;
  }

  const element = array[fromIndex];
  array.splice(fromIndex, 1); // Hapus elemen dari posisi asli
  array.splice(toIndex, 0, element); // Sisipkan elemen ke posisi baru

  return array
}


const SitemapSortModal = () => {
  const {
    getSitemapTree,
    sitemapTree,
    changeOrder,
    changeOrderLoading,
    setSortedSitemap,
    sortedSitemap
  } = useStore().sitemapStore;

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormik({
    validationSchema: yup.object({
      orderNumber: yup.number().required('Urutan tidak boleh kosong').min(1, 'Urutan tidak boleh kurang dari 1').max(sitemapTree.length, 'Urutan tidak boleh lebih dari jumlah item ' + sitemapTree.length)
    }),
    initialValues: {
      orderNumber: (sortedSitemap as any)?.orderNumber ?? undefined,
    },
    onSubmit: async (values) => {
      if (!sortedSitemap) return;
      
      const { orderNumber } = values
      const oldOrderNumber = sitemapTree.map(i => i.id).indexOf(sortedSitemap.id)
      
      const sorted = moveElement(sitemapTree, oldOrderNumber, orderNumber - 1)

      const payload = sorted?.map(i => ({
        id: i.id
      }))

      if (!payload) throw new Error('Payload is not found');
      
      await changeOrder(payload)
      await getSitemapTree()

      resetForm()
      setSortedSitemap(null)
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      sx={{ ".MuiPaper-root": { maxWidth: 500, width: "100%" } }}
      open={!!sortedSitemap}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
            Pindahkan item ke urutan:
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, gap: 1 }}>
            <TextField
              type="number"
              error={!!errors.orderNumber}
              helperText={errors.orderNumber as any}
              value={values.orderNumber}
              name='orderNumber'
              label="Urutan ke"
              onChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              setSortedSitemap(null);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default observer(SitemapSortModal);
