import { Button, Drawer } from "@mui/material";
import { ITemplateDetail } from "../../../app/models/template";
import { TailwindProvider } from "../../../app/component/TailwindProvider";
import { renderHtml } from "../../../utils/renderHtml";
import { ComponentAttributeType } from "../../../app/config/enum";
import { PagePayload } from "../../../app/models/page";
import { Fragment } from "react";
import { Close } from "@mui/icons-material";

interface PreviewPageProps {
  isOpen: boolean;
  onClose: () => void;
  components: ITemplateDetail['components'];
  pageComponentAttributesValue: PagePayload['pageComponentAttributesValue'];
}

export const PreviewPage = ({ isOpen, onClose, components, pageComponentAttributesValue }: PreviewPageProps) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{ ".MuiPaper-root": { ml: 'auto', width: "100%", position: "relative" } }}
    >
      <TailwindProvider>
        {components?.map((component, index) => {
          let temp = component.html;

          component.componentAttributes.forEach(attr => {
            const current = pageComponentAttributesValue?.find(i => i.componentAttributeId === attr.componentAttributesId)
            let value = current?.value;
            
            if (attr.type === ComponentAttributeType.IMAGE) {
              value = current?.fileUrl ?? ''
            }
            
            temp = temp.replace('$' + attr.name, value as any ?? attr.name) 
          })
          
          return <Fragment key={index}>{renderHtml(temp)}</Fragment>
        })}
      </TailwindProvider>
      <Button
        sx={{
          position: "fixed",
          bottom: '50px',
          right: '50px',
          padding: '18px',
          borderRadius: '100px'
        }}
        variant="contained"
        size="small"
        onClick={onClose}
        startIcon={<Close />}
      >
        Close
      </Button>
    </Drawer>
  );
};
