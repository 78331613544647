import { makeAutoObservable, runInAction } from "mobx";
import { IDashboardContentType, IDashboardPage } from "../models/dashboard";
import agent from "../api/agent";

export class DashboardStore {
  dashboardPage: IDashboardPage | null = null
  dashboardContentType: IDashboardContentType[] = []
  
  dashboardPageLoading: boolean = false
  dashboardContentTypeLoading: boolean = false
  
  constructor () {
    makeAutoObservable(this)
  }

  getDashboardPage = async () => {
    this.dashboardPageLoading = true
    try {
      const res = await agent.Dashboard.page()

      runInAction(() => this.dashboardPage = res)
    } finally {
      runInAction(() => this.dashboardPageLoading = false)
    }
  }

  getDashboardContentType = async () => {
    this.dashboardContentTypeLoading = true
    try {
      const res = await agent.Dashboard.contentType()

      runInAction(() => this.dashboardContentType = res)
    } finally {
      runInAction(() => this.dashboardContentTypeLoading = false)
    }
  }
}