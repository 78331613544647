import { Avatar, Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material"
import { useStore } from "../../stores/store"
import { Key, Logout, Settings } from "@mui/icons-material"
import { Link } from "react-router-dom"

export const CurrentAccount = () => {
  const { accountStore, modalStore } = useStore()
  
  return (
    <Stack spacing={2} borderTop="1px solid white">
      <ListItemButton
        sx={{
          borderRadius: ({ shape }) => shape.borderRadius + "px",
          ":hover": {
            "&.Mui-selected": {
              backgroundColor: "#025c9c",
            },
          },
          ".MuiTypography-root": {
            color: "white !important",
          },
          ".MuiSvgIcon-root": {
            color: "white !important",
          },
          "&.Mui-selected": {
            backgroundColor: "#025c9c",
          },
        }}
        component={Link}
        to={"/settings/change-password"}
      >
        <ListItemIcon sx={{ minWidth: "30px !important" }}><Settings /></ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: 2 }}>
        <Avatar alt={accountStore.account?.username} src={accountStore.account?.photo} />
        <Box flex={1}>
          <Typography sx={{ pl: 2, color: 'white' }} variant='h3' fontWeight={600}>{accountStore.account?.displayName ?? 'Guest'}</Typography>
          <Typography mt={.5} sx={{ pl: 2, color: 'white' }} fontSize={12} variant='h3'>{accountStore.account?.username ?? '-'}</Typography>
        </Box>
        <Logout onClick={() => modalStore.open(<LogoutModal />)} sx={{ cursor: 'pointer' }} color="error" />
      </Box>
    </Stack>
  )
}

const LogoutModal = () => {
  const { logout } = useStore().accountStore
  const { modalStore } = useStore()
  
  return (
    <Box>
      <Typography mb={2} fontSize={20} fontWeight={600} color="black">End session</Typography>
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <Button onClick={modalStore.close} color="inherit">Cancel</Button>
        <Button onClick={() => {
          logout()
          modalStore.close()
        }} color="error" variant="contained">Logout</Button>
      </Stack>
    </Box>
  )
}