import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import MenuList from "./MenuList";
import { Menu, menus } from "../../config/menu";
import { Stack } from "@mui/material";
import { useStore } from "../../stores/store";
import { CurrentAccount } from "./CurrentAccount";
import { useEffect, useState } from "react";
import { Roles } from "../../config/enum";

interface Props {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

const Sidebar = (props: Props) => {
  const { account } = useStore().accountStore;
  const { window, mobileOpen, handleDrawerToggle, drawerWidth } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const { getDropdown } = useStore().contentTypeMasterStore;

  const [displayedMenu, setDisplaymenu] = useState<any>([]);

  useEffect(() => {
    if (displayedMenu.length === 0) {
      getDropdown().then((res) => {
        const data = menus.map((i: any) =>
          i.title === "Content Types"
            ? {
                ...i,
                submenu: i.submenu.map((sm: any) =>
                  sm.title === "List"
                    ? {
                        ...sm,
                        submenu: res.map((ct) => ({
                          title: ct.text,
                          route: `/content-types/list/${ct.value}?name=${ct.text}`,
                          roles: [Roles.Admin, Roles.Approver, Roles.Creator, Roles.Publisher]
                        })),
                      }
                    : sm
                ),
              }
            : i
        )
        const checkAccess = (menus: Menu[]) => {
          return menus.map((menu) => {
              const allowedPermissions = menu.roles?.some((role) =>
                account?.roles.includes(role)
              );

              if (allowedPermissions || account?.roles.includes(Roles.Superadmin)) {
                if (menu.submenu) {
                  const updatedSubmenu = checkAccess(menu.submenu) as any
  
                  return { ...menu, submenu: updatedSubmenu }
                } 
                return menu;
              }

              return null;
            }).filter(Boolean)
        }
        
        setDisplaymenu(checkAccess(data));
      });
    }
  }, [account?.roles, getDropdown, menus]);

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="menu"
    >
      {/* Mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "flex", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: ({ palette }) => palette.primary.main,
          },
          flexDirection: "column",
        }}
      >
        <Stack
          component="div"
          sx={{ bgcolor: "background.default", height: "100%", px: 2.5, py: 3 }}
        >
          <Box
            sx={{
              display: "flex",
              bgColor: "primary",
              justifyContent: "center",
              mb: "36px",
            }}
          >
            <img src="/assets/images/logo-white.png" alt="Logo" width={228} />
          </Box>
          <MenuList menu={displayedMenu} />
        </Stack>
      </Drawer>

      {/* PC */}
      <Drawer
        container={container}
        variant={"permanent"}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={handleDrawerToggle}
        sx={{
          backgroundColor: "white",
          display: { xs: "none", sm: "flex" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            backgroundColor: ({ palette }) => palette.primary.main,
            boxShadow: "0 0 8px 4px rgba(0,0,0, .05)",
          },
          flexDirection: "column",
        }}
        open={props.mobileOpen}
      >
        <Stack component="div" sx={{ height: "100%", px: 2.5, py: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: "36px" }}>
            <img src="/assets/images/logo-white.png" alt="Logo" width={228} />
          </Box>
          <Box flex={1}>
            <MenuList menu={displayedMenu} />
          </Box>
          <CurrentAccount />
        </Stack>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
