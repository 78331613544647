import { observer } from "mobx-react-lite";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { ContentTypeName, ContentTypeStatus } from "../../../app/config/enum";
import { Checkbox, Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { DeleteRounded, EditRounded, Visibility } from "@mui/icons-material";
import { history } from "../../..";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";
import { format } from "date-fns";
import DeleteContentTypeModal from "./DeleteContentTypeModal";
import { convertUTCtoGMTplus7 } from "../../../utils/convertUTCtoGMTplus7";

interface ContentTypeGridProps {
  contentTypeName: ContentTypeName;
}

const ContentTypeGrid = ({ contentTypeName }: ContentTypeGridProps) => {
  const { id } = useParams();
  const query = useQuery();
  const name = query.get("name");
  const {
    grid,
    getGrid,
    gridLoading,
    params,
    setParams,
    deleteContentType,
    deleteLoading,
  } = useStore().contentTypeStore;
  const { modalStore, accountStore } = useStore();
  const { isSuperAdmin, isCreator } = accountStore;

  const canEdit = isSuperAdmin || isCreator;

  useEffect(() => {
    getGrid(contentTypeName);
  }, [getGrid, contentTypeName]);

  return (
    <StyledDataGridPremium
      loading={gridLoading}
      getRowId={(row) => {
        return row.contentTypeId + Math.random();
      }}
      columns={[
        {
          field: "action",
          headerName: "",
          minWidth: 140,
          renderCell: (params) => (
            <Stack direction="row">
              <Tooltip title={"View"}>
                <IconButton
                  color={"inherit"}
                  size="small"
                  onClick={() =>
                    history.push(
                      `/content-types/${id}/form/${params.row.id}?name=${name}&disabled=true`
                    )
                  }
                >
                  <Visibility fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Edit"}>
                <IconButton
                  disabled={
                    params.row.status === ContentTypeStatus.SUBMITTED ||
                    params.row.status === ContentTypeStatus.PUBLISHED ||
                    params.row.status === ContentTypeStatus.APPROVED ||
                    !canEdit
                  }
                  color={"info"}
                  size="small"
                  onClick={() =>
                    history.push(
                      `/content-types/${id}/form/${params.row.id}?name=${name}`
                    )
                  }
                >
                  <EditRounded fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  disabled={
                    params.row.status === ContentTypeStatus.SUBMITTED ||
                    params.row.status === ContentTypeStatus.ON_SCHEDULLED ||
                    params.row.status === ContentTypeStatus.PUBLISHED ||
                    // params.row.status === ContentTypeStatus.UNPUBLISHED ||
                    params.row.status === ContentTypeStatus.APPROVED ||
                    !canEdit
                  }
                  size="small"
                  onClick={() =>
                    modalStore.open(
                      <DeleteContentTypeModal id={params.row.id} name={name!} />
                    )
                  }
                >
                  <DeleteRounded fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        },
        {
          field: "name",
          headerName: "Name",
          width: 250,
        },
        {
          field: "createdByName",
          headerName: "Created by",
          width: 250,
        },
        {
          field: "status",
          headerName: "Created by",
          width: 140,
          renderCell: (params) => (
            <Chip
              color={
                (params.row.status === ContentTypeStatus.APPROVED &&
                  "success") ||
                (params.row.status === ContentTypeStatus.DRAFT && "warning") ||
                (params.row.status === ContentTypeStatus.ON_SCHEDULLED &&
                  "default") ||
                (params.row.status === ContentTypeStatus.PUBLISHED &&
                  "primary") ||
                (params.row.status === ContentTypeStatus.REJECTED && "error") ||
                (params.row.status === ContentTypeStatus.SUBMITTED &&
                  "default") ||
                (params.row.status === ContentTypeStatus.UNPUBLISHED &&
                  "default") ||
                "default"
              }
              label={params.row.status}
            />
          ),
        },
        {
          field: "notesApproval",
          headerName: "Approval Notes",
          minWidth: 120,
        },
        {
          field: "notesPublisher",
          headerName: "Publisher Notes",
          minWidth: 120,
        },
        {
          field: "createdOn",
          headerName: "Created On",
          width: 250,
          renderCell: (params) => (
            <>
              {format(
                convertUTCtoGMTplus7(params.row.createdOn),
                "dd MMM yyyy - HH:mm"
              )}
            </>
          ),
        },
        {
          field: "isApprove",
          headerName: "Approved?",
          width: 100,
          renderCell: (params) => (
            <Checkbox disabled checked={params.row.isApprove} />
          ),
        },
        {
          field: "isPublish",
          headerName: "Published?",
          width: 100,
          renderCell: (params) => (
            <Checkbox disabled checked={params.row.isPublish} />
          ),
        },
      ]}
      rows={grid.data}
      rowCount={grid.rowCount}
      pageSize={params.pageSize}
      page={params.currentPage}
      onPageChange={(v) => setParams("currentPage", v)}
      rowsPerPageOptions={[25, 50, 100]}
      onPageSizeChange={(v) => setParams("pageSize", v)}
      pagination
      paginationMode="server"
      autoHeight
    />
  );
};

export default observer(ContentTypeGrid);
