import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import DialogStore from "./helper/dialogStore";
import ExampleStore from "./exampleStore";
import ModalStore from "./helper/modalStore";
import SnackbarStore from "./helper/snackbarStore";
import UserStore from "./userStore";
import { ComponentStore } from "./componentStore";
import { TemplateStore } from "./templateStore";
import { DndTemplateStore } from "./dndTemplateStore";
import { PageStore } from "./pageStore";
import { ContentTypeStore } from "./contentTypeStore";
import { SitemapStore } from "./sitemapStore";
import { MediaStore } from "./mediaStore";
import { LanguageStore } from "./languageStore";
import { PageApprovalStore } from "./pageApprovalStore";
import { ContentTypeApprovalStore } from "./contentTypeApprovalStore";
import { PagePublishStore } from "./pagePublishStore";
import { ContentTypeMasterStore } from "./contentTypeMasterStore";
import { ContentTypePublishStore } from "./contentTypePublishStore";
import { ContentTypeCategoryStore } from "./contentTypeCategoryStore";
import { TimelineStore } from "./timelineStore";
import { DashboardStore } from "./dashboardStore";
import { MediaCropperStore } from "./mediaCropperStore";
import { SessionStore } from "./sessionStore";

export const store = {
    exampleStore: new ExampleStore(),
    commonStore: new CommonStore(),
    accountStore: new AccountStore(),
    userStore: new UserStore(),
    snackbarStore: new SnackbarStore(),
    modalStore: new ModalStore(),
    dialogStore: new DialogStore(),
    componentStore: new ComponentStore(),
    templateStore: new TemplateStore(),
    dndTemplateStore: new DndTemplateStore(),
    pageStore: new PageStore(),
    contentTypeStore: new ContentTypeStore(),
    sitemapStore: new SitemapStore(),
    mediaStore: new MediaStore(),
    languageStore: new LanguageStore(),
    pageApprovalStore: new PageApprovalStore(),
    contentTypeApprovalStore: new ContentTypeApprovalStore(),
    pagePublishStore: new PagePublishStore(),
    contentTypeMasterStore: new ContentTypeMasterStore(),
    contentTypePublishStore: new ContentTypePublishStore(),
    contentTypeCategoryStore: new ContentTypeCategoryStore(),
    timelineStore: new TimelineStore(),
    dashboardStore: new DashboardStore(),
    mediaCropperStore: new MediaCropperStore(),
    sessionStore: new SessionStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}