import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";

interface DeleteMediaModalProps {
  id: string;
}

const DeleteMediaModal = ({ id }: DeleteMediaModalProps) => {
  const { modalStore, mediaStore } = useStore();

  const handleDelete = async () => {
    await mediaStore.deleteMedia(id);
    modalStore.close()
    mediaStore.getFileGrid()
    mediaStore.getImageGrid()
    mediaStore.getVideoGrid()
  };

  return (
    <Box>
      <DialogTitle fontWeight={600} color="black">
        Delete Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to remove this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={modalStore.close} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          loading={mediaStore.deleteMediaLoading}
          onClick={handleDelete}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

export default observer(DeleteMediaModal);
