import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom";
import PageDataGrid from "./components/PageDataGrid";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

export const PagePage = observer(() => {
  const { isSuperAdmin, isCreator } = useStore().accountStore
  
  return (
    <Box>
      <Helmet>
        <title>Page | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        pageTitle="Pages"
        breadcrumbs={[
          { label: 'pages', href: '/pages/list' },
          { label: 'list', href: '/pages/list' },
        ]}
      >
        {isSuperAdmin || isCreator 
          ? <Link to="/pages/form">
              <Button variant="contained">Add Page</Button>
            </Link>
          : <Box><Button disabled variant="contained">Add Page</Button></Box>
        }
      </DashboardLayoutHeader>
      <PageDataGrid />
    </Box>
  )
})