import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import RkdBackdrop from "./RkdBackdrop";
import { Typography } from "@mui/material";

function RkdModal() {
    const { modalStore } = useStore();

    return (
        <Modal open={modalStore.modal.open} onClose={modalStore.close} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '90vw',
                maxHeight: '90vh',
                overflow: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2,
                borderRadius: ({ shape }) => shape.borderRadius + 'px'
            }}>
                {modalStore.modal.title && <Typography color="inherit" fontWeight={600} mb={2} variant="h3" fontSize="20px" id="modal-title">{modalStore.modal.title}</Typography>}
                {modalStore.modal.loading && <RkdBackdrop />}
                {modalStore.modal.body}
            </Box>
        </Modal>
    );
}

export default observer(RkdModal);