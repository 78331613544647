import { Box, Button, FormControl, Stack, TextField, Typography } from "@mui/material";
import { ComponentAttributeType } from "../../../app/config/enum";
import { ChangeEvent } from "react";
import { RichTextEditor } from "../../../app/component/RichTextEditor";
import { PagePayload } from "../../../app/models/page";
import { Article } from "@mui/icons-material";

interface PageFormTemplateFormFieldProps {
  type: string;
  name: string;
  id: string;
  handleOpenMediaModal: (name: string, type: string) => void;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type?: string
  ) => void;
  values: PagePayload['pageComponentAttributesValue'];
  disabled?: boolean;
}

export const PageFormTemplateFormField = ({
  type,
  name,
  id,
  onChange,
  values,
  disabled,
  handleOpenMediaModal,
}: PageFormTemplateFormFieldProps) => {
  switch (type) {
    case ComponentAttributeType.TEXT: {
      return (
        <TextField
          disabled={disabled}
          name={id}
          onChange={onChange}
          fullWidth
          value={
            values?.find(
              (i) => i.componentAttributeId === id
            )?.value
          }
          label={name}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }
    case ComponentAttributeType.TEXT_AREA: {
      return (
        <TextField
          name={id}
          disabled={disabled}
          onChange={onChange}
          fullWidth
          value={
            values?.find(
              (i) => i.componentAttributeId === id
            )?.value
          }
          label={name}
          size="small"
          rows={5}
          multiline
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }
    case ComponentAttributeType.IMAGE: {
      const preview = values?.find(
        (i) => i.componentAttributeId === id
      )?.fileUrl;

      return (
        <FormControl>
          <Typography color="#534e48" fontSize={11} ml={2}>
            {name}
          </Typography>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              border: ({ palette }) => `1px solid ${palette.divider}`,
            }}
            disabled={disabled}
            component={Button}
            onClick={() => handleOpenMediaModal(id, type)}
          >
            {preview ? (
              <img
                alt=""
                src={
                  values?.find(
                    (i) => i.componentAttributeId === id
                  )?.fileUrl ?? ""
                }
              />
            ) : (
              "Select Image"
            )}
          </Box>
        </FormControl>
      );
    }
    case ComponentAttributeType.VIDEO: {
      const preview = values?.find(
        (i) => i.componentAttributeId === id
      )?.fileUrl;

      return (
        <FormControl>
          <Typography color="#534e48" fontSize={11} ml={2}>
            {name}
          </Typography>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              border: ({ palette }) => `1px solid ${palette.divider}`,
            }}
            disabled={disabled}
            component={Button}
            onClick={() => handleOpenMediaModal(id, type)}
          >
            {preview ? (
              <video
                src={
                  values?.find(
                    (i) => i.componentAttributeId === id
                  )?.fileUrl ?? ""
                }
              />
            ) : (
              "Select Video"
            )}
          </Box>
        </FormControl>
      );
    }
    case ComponentAttributeType.FILE: {
      const preview = values?.find(
        (i) => i.componentAttributeId === id
      )?.fileUrl;

      return (
        <FormControl>
          <Typography color="#534e48" fontSize={11} ml={2}>
            {name}
          </Typography>
          <Box
            sx={{
              width: "200px",
              height: "200px",
              border: ({ palette }) => `1px solid ${palette.divider}`,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            disabled={disabled}
            component={preview ? Box : Button}
            onClick={() => preview ? null : handleOpenMediaModal(id, type)}
          >
            {preview ? (
              <Stack>
                <Button startIcon={<Article />} onClick={() => window.open(preview)}>
                  Preview File
                </Button>
                <Button variant="outlined" onClick={() => handleOpenMediaModal(id, type)}>
                  Change
                </Button>
              </Stack>
            ) : (
              "Select File"
            )}
          </Box>
        </FormControl>
      );
    }
    case ComponentAttributeType.RICH_TEXT: {
      return (
        <RichTextEditor
          disabled={disabled}
          value={
            values?.find(
              (i) => i.componentAttributeId === id
            )?.value ?? (`` as any)
          }
          label={name}
          name={name}
          onChange={(value) => onChange({ target: { value, name: id } } as any)}
        />
      );
    }
    // case ComponentAttributeType.VIDEO: {
    //   return (
    //     <Box>
    //       <Typography color="#534e48" fontSize={11} ml={2}>
    //         {name}
    //       </Typography>
    //       <input 
    //         accept="video/mp4,video/x-m4v,video/*"
    //         type="file"
    //         value={values.find(
    //           (i) => i.componentAttributeId === id
    //         )?.value ?? (`` as any)}
    //         name={name} 
    //         onChange={e => onChange(e, '') as any} 
    //       />
    //     </Box>
    //   );
    // }
    case ComponentAttributeType.NUMBER: {
      return (
        <Box>
          <Typography color="#534e48" fontSize={11} ml={2}>
            {name}
          </Typography>
          <TextField 
            type="number"
            disabled={disabled}
            value={values?.find(
              (i) => i.componentAttributeId === id
            )?.value ?? (`` as any)}
            name={name} 
            fullWidth
            onChange={onChange as any} 
          />
        </Box>
      );
    }
    // case ComponentAttributeType.FILE: {
    //   return (
    //     <Box>
    //       <Typography color="#534e48" fontSize={11} ml={2}>
    //         {name}
    //       </Typography>
    //       <input 
    //         accept="application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain,application/pdf"
    //         type="file"
    //         value={values.find(
    //           (i) => i.componentAttributeId === id
    //         )?.value ?? (`` as any)}
    //         name={name} 
    //         onChange={onChange as any} 
    //       />
    //     </Box>
    //   );
    // }
    default: {
      return null;
    }
  }
};
