import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DeleteRounded, HeightRounded, HideImageRounded } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { IItemValue } from "./DNDController";
import { SORTABLE_ITEM } from "../helper/constant";
import * as dompurify from "dompurify";
import { useStore } from "../../../app/stores/store";
import { renderHtml } from "../../../utils/renderHtml";
import { DragOverlay } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

interface SortableDNDItemProps {
  item: IItemValue;
}

export function SortableItemOverlay({ item }: SortableDNDItemProps) {
  const skeleton = dompurify.sanitize(item.htmlSkeleton ?? "<div />");
  
  return (
    <DragOverlay adjustScale={false} modifiers={[restrictToVerticalAxis]}>
      <Box sx={{ position: "relative", width: "100%", boxShadow: `rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;` }}>
        <Box
          sx={{
            backgroundColor: "#ECEDEE",
            p: 2,
          }}
          borderRadius={1}
        >
          {!!item.htmlSkeleton ? (
            renderHtml(skeleton)
          ) : (
            <Stack color="ActiveBorder" p={2} alignItems="center">
              <HideImageRounded />
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            p: 1,
          }}
        >
          <IconButton size="small">
            <HeightRounded fontSize="small" />
          </IconButton>
        </Box>
        <Box
          position={"absolute"}
          top={2}
          left={2}
          py={0.2}
          px={0.5}
          sx={{
            backgroundColor: "white",
            borderRadius: ({ shape }) => shape.borderRadius,
          }}
        >
          <Typography fontSize={"10px"}>{item.name}</Typography>
        </Box>
      </Box>
    </DragOverlay>
  );
}
