import DOMPurify from "dompurify"
import ReactHtmlParser from 'react-html-parser'

export const renderHtml = (html: string) => {
  const clean = html // DOMPurify.sanitize(html)
  
  const options = {
    transform : function transform(node :any, index:any) {
       if ( node.type === 'script') {
          return <script key={index} src={node?.attribs?.src}>{node?.children[0]?.data}</script>;
      }
    }
  };

  const classClean = clean.replaceAll('className', 'class')
  
  const htmlResult = ReactHtmlParser(classClean, options)
  const body = document.querySelector('body');
  
  return htmlResult
}

