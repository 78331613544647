import { useRef } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Box, Button, Modal } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";

export const MediaCropper = observer(() => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const { mediaUri, cropMedia, cropLoading, clearMediaUri, closeMediaCropper } =
    useStore().mediaCropperStore;
  const { createMediaLoading, getImageGrid, createMedia } = useStore().mediaStore;
  
  const handleCrop = async () => {
    const res = await cropMedia(cropperRef.current)
    
    if (!res) return;
    
    await createMedia(res.formData)
    getImageGrid()
    clearMediaUri()
  }
  
  return (
    <Modal open={!!mediaUri}>
      {!!mediaUri ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: 'black' }}
        >
          <Cropper
            src={mediaUri}
            style={{ height: "90%", width: "100%" }}
            initialAspectRatio={16 / 9}
            guides={false}
            ref={cropperRef}
          />
          <Box
            sx={{ backgroundColor: "black", width: "100%" }}
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            gap={5}
            flexDirection="row"
            flexShrink={0}
            maxHeight={200}
          >
            <Button
              disabled={createMediaLoading}
              sx={{ width: 100, height: 50 }}
              variant="contained"
              color="error"
              onClick={() => closeMediaCropper()}
            >
              Cancel
            </Button>
            <LoadingButton
              sx={{ width: 100, height: 50 }}
              variant="contained"
              color="primary"
              onClick={handleCrop}
              loading={cropLoading}
            >
              Crop
            </LoadingButton>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  );
});
