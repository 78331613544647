import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IContentTypeMasterDetail, IContentTypeMasterDropdown } from "../models/contentTypeMaster";

export class ContentTypeMasterStore {
  contentTypeMasterDropdown: IContentTypeMasterDropdown[] = []
  contentTypeMasterDropdownLoading = false

  detail: IContentTypeMasterDetail | null = null
  detailLoading = false
  
  constructor() {
    makeAutoObservable(this)
  }

  getDropdown = async () => {
    this.contentTypeMasterDropdownLoading = true;
    try {
      const res = await agent.ContentTypeMaster.dropdown()
      
      runInAction(() => this.contentTypeMasterDropdown = res);

      return res;
    } finally {
      runInAction(() => this.contentTypeMasterDropdownLoading = false);
    }
  }

  getDetail = async (id: string) => {
    this.detailLoading = true;
    try {
      const res = await agent.ContentTypeMaster.detail(id)

      runInAction(() => this.detail = res)
    } finally {
      runInAction(() => this.detailLoading = false)
    }
  }

  resetDetail = () => {
    this.detail = null;
  }
}