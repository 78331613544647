import { makeAutoObservable, reaction, runInAction } from "mobx";
import { ITemplate, ITemplateDetail, ITemplateParams, ITemplatePayload } from "../models/template";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import DataGridStore from "./materialUI/dataGridStore";

export class TemplateStore {
  templateGrid: DataGridResult<ITemplate[]> = new DataGridResult([], 0)
  templateGridLoading = false;

  template: ITemplate[] = [];
  templateLoading = false;
  
  templateDetail: ITemplateDetail | null = null
  templateDetailLoading = false;

  deleteTemplateLoading = false;
  createTemplateLoading = false;
  duplicateTemplateLoading = false;

  params: ITemplateParams = {
    pageSize: 25,
    currentPage: 0,
  }

  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.params.currentPage, this.params.pageSize], () => this.getTemplateGrid())
  }

  createTemplate = async (body: ITemplatePayload) => {
    this.createTemplateLoading = true
    try {
      await agent.Template.create(body);
    } finally {
      runInAction(() => this.createTemplateLoading = false)
    }
  }

  updateTemplate = async (templateId: string, body: ITemplatePayload) => {
    this.createTemplateLoading = true
    try {
      await agent.Template.update(templateId, body);
    } finally {
      runInAction(() => this.createTemplateLoading = false)
    }
  }

  deleteTemplate = async (templateId: string) => {
    this.deleteTemplateLoading = true;
    try {
      await agent.Template.delete(templateId)
    } finally {
      runInAction(() => this.deleteTemplateLoading = false)
    }
  }

  duplicateTemplate = async (templateId: string) => {
    this.duplicateTemplateLoading = true
    try {
      await agent.Template.duplicate(templateId)
    } finally {
      runInAction(() => this.duplicateTemplateLoading = false)
    }
  }
  
  getTemplateGrid = async () => {
    this.templateGridLoading = true;
    try {
      const res = await agent.Template.listGrid(this.params)
      
      runInAction(() => this.templateGrid = res);
    } finally {
      runInAction(() => this.templateGridLoading = false)
    }
  }

  getTemplate = async (search?: string) => {
    this.templateLoading = true
    try {
      const res = await agent.Template.list(search)
      
      runInAction(() => this.template = res);
    } finally {
      runInAction(() => this.templateLoading = false)
    }
  }

  getTemplateDetail = async (templateId: string) => {
    this.templateDetailLoading = true
    try {
      const res = await agent.Template.detail(templateId)

      this.templateDetail = res
    } finally {
      runInAction(() => this.templateDetailLoading = false)
    }
  }
  clearTemplateDetail = () => {
    this.templateDetail = null
    this.templateDetailLoading = false
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value;
  }
}