import ComponentDataGrid from "./ComponentDataGrid";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";

const ComponentPage = () => {
  return (
    <>
      <DashboardLayoutHeader
        breadcrumbs={[
          { label: "components", href: "/components" },
          { label: "list", href: "/components" }
        ]}
        pageTitle="Components"
      />
      <ComponentDataGrid />
    </>
  );
};

export { ComponentPage };
