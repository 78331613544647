import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useState } from "react";
import { ApprovalResponseType } from "../../app/config/enum";
import agent from "../../app/api/agent";
import { useStore } from "../../app/stores/store";
import { ContentTypeApproveRejectTypeValues } from "../../app/models/contentTypeApproval";

interface ApproveRejectModalProps {
  isOpen: boolean;
  onClose: () => void;
  setApproveRejectState: Dispatch<SetStateAction<ContentTypeApproveRejectTypeValues | null>>
  approveRejectState: ContentTypeApproveRejectTypeValues | null
}

export const ApproveRejectModal = ({ isOpen, onClose, approveRejectState, setApproveRejectState }: ApproveRejectModalProps) => {
  const { getGrid } = useStore().contentTypeApprovalStore
  const [isSubmitting, setIsSubmitting] = useState(false)
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsSubmitting(true)
    try {
      await agent.ContentTypeApproval.approveReject(approveRejectState!)
      
      onClose()
      getGrid()
    } finally {
      setIsSubmitting(false)
    }
  }
  
  return (
    <Dialog
      sx={{ ".MuiPaper-root": { maxWidth: 500, width: "100%" } }}
      open={isOpen}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
            {approveRejectState?.status === ApprovalResponseType.APPROVED ? 'Approval note (Optional)' : 'Reject note *'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField 
            multiline
            rows={5}
            fullWidth
            value={approveRejectState?.notes}
            onChange={(v) => setApproveRejectState(prev => ({ ...prev!, notes: v.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color={approveRejectState?.status === ApprovalResponseType.APPROVED ? 'success' : 'error'}
            variant="contained"
          >
            {approveRejectState?.status === ApprovalResponseType.APPROVED ? 'Approve' : 'Reject'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}