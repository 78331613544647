import { Box, BoxProps, Typography } from "@mui/material";
import * as MaterialIcon from "@mui/icons-material";
import { IComponent } from "../../../app/models/component";
import { forwardRef } from "react";

interface PanelItemProps extends BoxProps {
  item: IComponent;
  isOverlay?: boolean;
  disabled?: boolean;
}

export const DraggableItem = forwardRef<any, PanelItemProps>(
  ({ item, isOverlay, disabled, ...props }, ref) => {
    const { icon, name } = item;

    const Icon = !!icon ? MaterialIcon[icon] : MaterialIcon.QuestionMark;

    return (
      <Box
        ref={ref}
        sx={{
          border: ({ palette }) => `1px solid ${palette.divider}`,
          borderRadius: ({ shape }) => shape.borderRadius + "px",
          display: "flex",
          p: 1.5,
          overflow: "hidden",
          backgroundColor: '#ECEDEE',
          alignItems: 'center',
          gap: 1,
          opacity: disabled ? .5 : 1
        }}
        {...props}
      >
        <Box
          width={"30px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          color={'black'}
        >
          <Icon />
        </Box>
        <Typography
          fontWeight={600}
          textAlign={"center"}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          color={'GrayText'}
        >
          {name}
        </Typography>
      </Box>
    );
  }
);
