import { useEffect, useMemo } from "react"
import { useStore } from "../../app/stores/store"
import Chart from 'react-apexcharts'
import { observer } from "mobx-react-lite"
import { Box, CircularProgress, Stack, Toolbar } from "@mui/material"

export const PageChart = observer(() => {
  const { getDashboardPage, dashboardPageLoading, dashboardPage } = useStore().dashboardStore
  
  const labels = useMemo(() => {
    return !!dashboardPage ? Object.keys(dashboardPage) : []
  }, [dashboardPage])
  
  const data = useMemo(() => {
    let temp: number[] = [];
    
    if (labels && dashboardPage) {
      labels?.forEach(label => {
        temp.push((dashboardPage as any)[label])
      })
    }

    return temp
  }, [dashboardPage, labels])

  useEffect(() => {
    getDashboardPage()
  }, [getDashboardPage])

  if (dashboardPageLoading) return <CircularProgress />
  
  return (
    <Stack gap={2}>
      <Toolbar />
      <Chart
        options={{
          chart: {
            width: '100%',
            type: 'pie',
          },
          labels: labels,
          theme: {
            monochrome: {
              enabled: true
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5
              }
            }
          },
          title: {
            text: "Page"
          },
        }}
        series={data}
        type="pie"
        width="500"
      />
    </Stack>
  )
})