import { FormikProps } from "formik"
import { observer } from "mobx-react-lite"
import { useStore } from "../../app/stores/store"
import { useEffect } from "react"
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"

interface CategorySelectProps {
  formik: FormikProps<any>
}

const CategorySelect = ({ formik }: CategorySelectProps) => {
  const { getDropdown, contentTypeMasterDropdown, contentTypeMasterDropdownLoading } = useStore().contentTypeMasterStore
  
  useEffect(() => {
    getDropdown()
  }, [getDropdown])
  
  return (
    <FormControl fullWidth>
      <InputLabel id="select-parent-label">Parent</InputLabel>
      <Select
        labelId="select-parent-label"
        id="select-parent"
        value={formik.values.parentId}
        displayEmpty
        name="parentId"
        label="Parent"
        disabled={contentTypeMasterDropdownLoading}
        onChange={formik.handleChange}
        error={!!formik.errors.parentId}
      >
        {contentTypeMasterDropdown.map(item => (
          <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
        ))}
      </Select>
      {/* {!!formik.errors.parentId && <FormHelperText>{formik.errors.parentId as any}</FormHelperText>} */}
    </FormControl>
  )
}

export default observer(CategorySelect)