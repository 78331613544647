import { styled } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";

export const StyledDataGridPremium = styled(DataGridPremium)(({ theme }) => ({
  border: 'none',
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'white',
  '.StyledDataGrid-IsDeleted-Row': {
    backgroundColor: '#F0F0F0'
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
  },
  '.MuiDataGrid-main': {
    backgroundColor: 'white',
  }
}))

StyledDataGridPremium.defaultProps = {
  rowsPerPageOptions: [25, 50, 100],
  pageSize: 25,
}