import { makeAutoObservable, runInAction } from "mobx";
import { ILanguage } from "../models/language";
import agent from "../api/agent";

export class LanguageStore {
  language: ILanguage[] = [];
  languageLoading: boolean = false;
  
  constructor() {
    makeAutoObservable(this)
  }

  getList = async () => {
    this.languageLoading = true
    try {
      const res = await agent.Language.list();

      runInAction(() => this.language = res)
    } finally {
      runInAction(() => this.languageLoading = false)
    }
  }
}