import { renderHtml } from "../../utils/renderHtml"
import { DUMMY_IMG } from "./TestPage"

export const FrameCardWrapTextImage = () => {
  return (
    <>
      {renderHtml(`
        <div class='container px-[16px] mx-auto py-[54px]'>
          <h2 class='mb-[32px] font-[700] text-[36px] text-[#212121] text-center'>Tentang kami</h2>
          <div class='flex gap-[34px] flex-col md:flex-row'>
            <div class='p-[34px] flex flex-col justify-between h-[345px] rounded-[16px] md:flex-1 group relative overflow-hidden'>
              <img src='${DUMMY_IMG}' class='absolute -z-10 top-0 left-0 w-full h-full transform group-hover:scale-150 transition-all duration-300' alt="">
              <h4 class='font-[700] text-[48px] text-white'>Identitas Kami</h4>
              <a role='button' href='https://google.com' class='text-[20px] font-[700] text-white flex items-center gap-[8px]'>
                Selengkapnya
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.3 25.9999H10C9.43333 25.9999 8.95867 25.8079 8.576 25.4239C8.19333 25.0399 8.00133 24.5653 8 23.9999C8 23.4333 8.192 22.9586 8.576 22.5759C8.96 22.1933 9.43467 22.0013 10 21.9999H32.3L26.6 16.2999C26.2 15.8999 26.008 15.4333 26.024 14.8999C26.04 14.3666 26.232 13.8999 26.6 13.4999C27 13.0999 27.4753 12.8919 28.026 12.8759C28.5767 12.8599 29.0513 13.0513 29.45 13.4499L38.6 22.5999C38.8 22.7999 38.942 23.0166 39.026 23.2499C39.11 23.4833 39.1513 23.7333 39.15 23.9999C39.15 24.2666 39.1087 24.5166 39.026 24.7499C38.9433 24.9833 38.8013 25.1999 38.6 25.3999L29.45 34.5499C29.05 34.9499 28.5753 35.1419 28.026 35.1259C27.4767 35.1099 27.0013 34.9013 26.6 34.4999C26.2333 34.0999 26.0413 33.6333 26.024 33.0999C26.0067 32.5666 26.1987 32.0999 26.6 31.6999L32.3 25.9999Z" fill="white"/>
                </svg>
              </a>
            </div>
            <div class='p-[34px] flex flex-col justify-between h-[345px] rounded-[16px] md:flex-1 group relative overflow-hidden'>
              <img src='${DUMMY_IMG}' class='absolute -z-10 top-0 left-0 w-full h-full transform group-hover:scale-150 transition-all duration-300' alt="">
              <h4 class='font-[700] text-[48px] text-white'>Identitas Kami</h4>
              <a role='button' href='https://google.com' class='text-[20px] font-[700] text-white flex items-center gap-[8px]'>
                Selengkapnya
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.3 25.9999H10C9.43333 25.9999 8.95867 25.8079 8.576 25.4239C8.19333 25.0399 8.00133 24.5653 8 23.9999C8 23.4333 8.192 22.9586 8.576 22.5759C8.96 22.1933 9.43467 22.0013 10 21.9999H32.3L26.6 16.2999C26.2 15.8999 26.008 15.4333 26.024 14.8999C26.04 14.3666 26.232 13.8999 26.6 13.4999C27 13.0999 27.4753 12.8919 28.026 12.8759C28.5767 12.8599 29.0513 13.0513 29.45 13.4499L38.6 22.5999C38.8 22.7999 38.942 23.0166 39.026 23.2499C39.11 23.4833 39.1513 23.7333 39.15 23.9999C39.15 24.2666 39.1087 24.5166 39.026 24.7499C38.9433 24.9833 38.8013 25.1999 38.6 25.3999L29.45 34.5499C29.05 34.9499 28.5753 35.1419 28.026 35.1259C27.4767 35.1099 27.0013 34.9013 26.6 34.4999C26.2333 34.0999 26.0413 33.6333 26.024 33.0999C26.0067 32.5666 26.1987 32.0999 26.6 31.6999L32.3 25.9999Z" fill="white"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      `)}
    </>
  )
}