import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { IMedia, IMediaParams } from "../models/media";
import { DataGridResult } from "../models/materialUI/dataGrid";

export class MediaStore {
  fileGrid: DataGridResult<IMedia[]> = new DataGridResult([], 0)
  fileGridLoading = false;
  fileGridParams: IMediaParams = {
    currentPage: 0,
    pageSize: 12,
  }

  imageGrid: DataGridResult<IMedia[]> = new DataGridResult([], 0)
  imageGridLoading = false;
  imageGridParams: IMediaParams = {
    currentPage: 0,
    pageSize: 12,
  }

  videoGrid: DataGridResult<IMedia[]> = new DataGridResult([], 0)
  videoGridLoading = false;
  videoGridParams: IMediaParams = {
    currentPage: 0,
    pageSize: 12,
  }
  
  createMediaLoading: boolean = false;
  deleteMediaLoading: boolean = false;
  editMediaLoading: boolean = false;
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.fileGridParams.currentPage, this.fileGridParams.pageSize], () => this.getFileGrid())
    reaction(() => [this.imageGridParams.currentPage, this.imageGridParams.pageSize], () => this.getImageGrid())
    reaction(() => [this.videoGridParams.currentPage, this.videoGridParams.pageSize], () => this.getVideoGrid())
  }

  createMedia = async (body: FormData) => {
    this.createMediaLoading = true
    try {
      return await agent.Media.create(body)
    } finally {
      runInAction(() => this.createMediaLoading = false)
    }
  }

  editMedia = async (id: string, body: FormData) => {
    this.editMediaLoading = true
    try {
      return await agent.Media.edit(id, body)
    } finally {
      runInAction(() => this.editMediaLoading = false)
    }
  }

  deleteMedia = async (id: string) => {
    this.deleteMediaLoading = true;
    try {
      await agent.Media.delete(id)
    } finally {
      runInAction(() => this.deleteMediaLoading = false)
    }
  }

  getFileGrid = async () => {
    this.fileGridLoading = true
    try {
      const res = await agent.Media.grid('FILE', this.fileGridParams)
      runInAction(() => this.fileGrid = res)
    } finally {
      runInAction(() => this.fileGridLoading = false)
    }
  }

  setFileParams = (key: keyof typeof this.fileGridParams, value: number) => {
    this.fileGridParams[key] = value
  }

  getImageGrid = async () => {
    this.imageGridLoading = true
    try {
      const res = await agent.Media.grid('IMAGE', this.imageGridParams)
      runInAction(() => this.imageGrid = res)
    } finally {
      runInAction(() => this.imageGridLoading = false)
    }
  }

  setImageParams = (key: keyof typeof this.imageGridParams, value: number) => {
    this.imageGridParams[key] = value
  }

  getVideoGrid = async () => {
    this.videoGridLoading = true
    try {
      const res = await agent.Media.grid('VIDEO', this.videoGridParams)
      runInAction(() => this.videoGrid = res)
    } finally {
      runInAction(() => this.videoGridLoading = false)
    }
  }

  setVideoParams = (key: keyof typeof this.videoGridParams, value: number) => {
    this.videoGridParams[key] = value
  }
}