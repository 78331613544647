import { makeAutoObservable, runInAction } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IPagePublish, IPagePublishDetail, IPagePublishPayload, IPageUnpublishPayload } from "../models/pagePublish";
import agent from "../api/agent";
import { PublishStatus } from "../config/enum";
import { IContentTypePublish, IContentTypePublishDetail, IContentTypePublishPayload } from "../models/contentTypePublish";

export class ContentTypePublishStore {
  grid: DataGridResult<IContentTypePublish[]> = new DataGridResult([], 0)
  gridLoading = false

  detail: IContentTypePublishDetail | null = null
  detailLoading = false
  
  publishLoading = false;
  unpublishLoading = false;
  
  params = {
    currentPage: 0,
    pageSize: 25,
  }
  
  constructor() {
    makeAutoObservable(this)
  }

  getGrid = async () => {
    this.gridLoading = true
    try {
      const res = await agent.ContentTypePublish.getGrid(this.params)

      runInAction(() => this.grid = res)
    } finally {
      runInAction(() => this.gridLoading = false)
    }
  }

  getDetail = async (pageId: string) => {
    this.detailLoading = true;
    try {
      const res = await agent.ContentTypePublish.getDetail(pageId)
      
      runInAction(() => this.detail = res)
    } finally {
      runInAction(() => this.detailLoading = false)
    }
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value
  }

  publish = async (status: PublishStatus, body: IContentTypePublishPayload) => {
    this.publishLoading = true;
    try {
      return await agent.ContentTypePublish.publish(status, body)
    } finally {
      runInAction(() => this.publishLoading = false)
    }
  }

  unpublish = async (id: string, body: IPageUnpublishPayload) => {
    this.unpublishLoading = true;
    try {
      await agent.ContentTypePublish.unpublish(id, body)
    } finally {
      runInAction(() => this.unpublishLoading = false)
    }
  }
}