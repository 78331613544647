import { makeAutoObservable, runInAction } from "mobx";
import { ITimeline, TimelinePayload } from "../models/timeline";
import agent from "../api/agent";
import { DataGridResult, IDataGridResult } from "../models/materialUI/dataGrid";

export class TimelineStore {
  grid: IDataGridResult<ITimeline[]> = new DataGridResult([], 0)
  gridLoading = false
  
  list: ITimeline[] = []
  listLoading = false
  
  temp: ITimeline | null = null

  deleteLoading = false
  
  params: {
    CurrentPage: number;
	  PageSize: number;
  } = {
    CurrentPage: 0,
    PageSize: 25,
  }
  
  constructor () {
    makeAutoObservable(this)
  }

  getGrid = async () => {
    this.gridLoading = true;
    try {
      const res = await agent.Timeline.grid(this.params)

      runInAction(() => this.grid = res)
    } finally {
      runInAction(() => this.gridLoading = false)
    }
  }

  createTimeline = async (body: TimelinePayload) => {
    await agent.Timeline.create(body);
  }

  updateTimeline = async (id: string, body: TimelinePayload) => {
    await agent.Timeline.update(id, body)
  }
  
  deleteTimeline = async (id: string) => {
    this.deleteLoading = true;
    try {
      await agent.Timeline.delete(id)
    } finally {
      runInAction(() => this.deleteLoading = false)
    }
  }

  setTemp = (item: ITimeline | null) => {
    this.temp = item
  }

  getList = async () => {
    this.listLoading = true
    try {
      const res = await agent.Timeline.list()
      runInAction(() => this.list = res)
    } finally {
      runInAction(() => this.listLoading = false)
    }
  }
}