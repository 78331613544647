export function convertUTCtoGMTplus7(utcDateString: Date) {
  // Create a Date object from the UTC date string
  const utcDate = new Date(utcDateString);
  
  // Convert UTC time to GMT+7
  const gmtPlus7Date = new Date(utcDate.getTime() + (7 * 60 * 60 * 1000));
  
  // Return the GMT+7 date string
  return gmtPlus7Date
}

// export function convertUTCtoGMTplus7(utcDateString: Date) {
//   // Create a Date object from the UTC date string
//   const utcDate = new Date(utcDateString);
  
//   // Convert UTC time to GMT+7
//   const gmtPlus7Date = new Date(utcDate.getTime() + (7 * 60 * 60 * 1000));
  
//   // Define options for formatting
//   const options: Intl.DateTimeFormatOptions = {
//     timeZone: 'Asia/Bangkok', // or any other suitable timezone in GMT+7
//     hour12: false, // Use 24-hour format
//     hour: '2-digit', // Display hours in 2-digit format
//     minute: '2-digit', // Display minutes in 2-digit format
//     second: '2-digit', // Display seconds in 2-digit format
//   };

//   // Return the formatted GMT+7 date string
//   return gmtPlus7Date.toLocaleString('en-US', options);
// }
