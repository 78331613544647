import { makeAutoObservable } from "mobx"
import agent from "../api/agent"
import { ISession } from "../models/session";

export class SessionStore {
  session: number = 0;
  sessionLoading: boolean = false;

  constructor() {
    makeAutoObservable(this)
  }

  getSession = async () => {
    this.sessionLoading = true;
    try {
      const res = await agent.Session.get();
  
      this.session = res.session;
    } finally {
      this.sessionLoading = false;
    }
  }

  editSession = async (body: ISession) => {
    this.sessionLoading = true;

    try {
      await agent.Session.edit(body)
    } finally {
      this.sessionLoading = false;
    }
  }
}