import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import MenuList from './MenuList';
import { Menu, menus } from '../../config/menu';
import { Stack } from '@mui/material';
import { useStore } from '../../stores/store';
import { CurrentAccount } from './CurrentAccount';
import { useEffect, useState } from 'react';
import { Roles } from '../../config/enum';

interface Props {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

const Sidebar2 = (props: Props) => {
  const { account } = useStore().accountStore
  const { window, mobileOpen, handleDrawerToggle, drawerWidth } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  const { getDropdown } = useStore().contentTypeMasterStore;

  const [displayedMenu, setDisplaymenu] = useState<any>([]);

  useEffect(() => {
    if (displayedMenu.length === 0) {
      getDropdown().then((res) => {
        const data = menus.map((i: any) =>
          i.title === "Content Types"
            ? {
                ...i,
                submenu: i.submenu.map((sm: any) =>
                  sm.title === "List"
                    ? {
                        ...sm,
                        submenu: res.map((ct) => ({
                          title: ct.text,
                          route: `/content-types/list/${ct.value}?name=${ct.text}`,
                        })),
                      }
                    : sm
                ),
              }
            : i
        )
        const checkAccess = (menus: Menu[]) => {
          return menus.map((menu) => {
              const allowedPermissions = menu.roles?.some((role) =>
                account?.roles.includes(role)
              );

              if (allowedPermissions || account?.roles.includes(Roles.Superadmin)) {
                if (menu.submenu) {
                  const updatedSubmenu = checkAccess(menu.submenu) as any
  
                  return { ...menu, submenu: updatedSubmenu }
                } 
                return menu;
              }

              return null;
            }).filter(Boolean)
        }
        
        setDisplaymenu(checkAccess(data));
      });
    }
  }, [account?.roles, getDropdown, menus]);
  
  return (
    <Box
      component="nav"
      sx={{ flexShrink: { sm: 0 }, }}
      aria-label="menu"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#006CB8' },
          flexDirection: 'column',
        }}
      >
        <Stack component='div' sx={{ height: '100%', px: 2.5, py: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: '36px' }}>
            <img src="/assets/images/logo-white.png" alt="Logo" width={228} />
          </Box>
          <Box flex={1}>
            <MenuList menu={displayedMenu} />
          </Box>
          <CurrentAccount />
        </Stack>
      </Drawer>
    </Box>
  );
};

export default Sidebar2;
