import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "../../app/stores/store";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { DNDController } from "./components/DNDController";
import { SpaceDashboardRounded } from "@mui/icons-material";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import { ITemplatePayload } from "../../app/models/template";
import { useFormik } from "formik";
import * as yup from "yup";
import { toJS } from "mobx";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import RkdBackdrop from "../../app/component/helper/RkdBackdrop";
import { nanoid } from "nanoid";

const TemplateFormPage = () => {
  const [panelOpen, setPanelOpen] = useState(true);
  const { modalStore, templateStore, dndTemplateStore } = useStore();
  const { dndItems, setDndItems, resetDnd } = dndTemplateStore;
  const {
    createTemplate,
    clearTemplateDetail,
    getTemplateDetail,
    templateDetail,
    updateTemplate,
    templateDetailLoading,
  } = templateStore;

  const params = useParams();
  const isUpdate = useMemo(() => params.id, [params]);
  const initialValues = useMemo(
    () => ({
      name: templateDetail ? templateDetail.name : "",
      components: false,
    }),
    [templateDetail]
  );

  const {
    handleSubmit,
    isSubmitting,
    values,
    setErrors,
    resetForm,
    errors,
    handleChange,
    setFieldValue,
    submitForm,
  } = useFormik({
    validationSchema: yup.object({
      name: yup.string().required(),
      components: yup.bool().oneOf([true], "Please add at lease 1 component"),
    }),
    initialValues,
    onSubmit: async (values) => {
      if (templateDetail && params.id) {
        await updateTemplate(params.id, getPayload(values.name)).then(() =>
          history.push("/templates")
        );
      } else {
        await createTemplate(getPayload(values.name)).then(() =>
          history.push("/templates")
        );
      }
    },
    enableReinitialize: true,
  });
  
  // useEffect(() => {
  //   console.log(values, errors)
  // }, [values, errors])

  const getPayload = (name: string): ITemplatePayload => ({
    name,
    components: dndItems.map((item, index) => ({
      componentId: item.id,
      orderNumber: index + 1,
    })),
  });
  
  console.log('dnd items:', toJS(dndItems))

  useEffect(() => {
    if (Array.isArray(dndItems) && dndItems.length > 0) {
      setFieldValue("components", true, false);
    } else {
      setFieldValue("components", false, false);
    }
    setErrors({});
  }, [dndItems, setFieldValue, setErrors]);

  useEffect(() => {
    if (params.id) {
      getTemplateDetail(params.id);
    }
  }, [params, getTemplateDetail]);

  useEffect(() => {
    if (templateDetail && params.id) {
      setDndItems(
        templateDetail.components.map((component) => {
          const itemId = nanoid();

          return {
            componentAttributes: component.componentAttributes.map(
              (attribute) => ({
                id: attribute.componentAttributesId,
                componentId: attribute.componentsId,
                name: attribute.name,
                type: attribute.type,
              })
            ),
            description: component.description,
            // displayName: component.displayName,
            name: component.name,
            html: component.html,
            htmlSkeleton: component.htmlSkeleton,
            icon: "QuestionMark",
            id: component.componentId,
            itemId,
            contentTypeId: component.contentTypeId,
          };
        })
      );
    }
  }, [templateDetail, params.id, setDndItems]);

  const resetState = useCallback(() => {
    resetDnd();
    resetForm({
      isSubmitting: false,
      values: {
        components: false,
        name: "",
      },
      errors: {
        components: undefined,
        name: undefined,
      },
    });
    clearTemplateDetail();
  }, [resetDnd, resetForm, clearTemplateDetail]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  const onCancel = () => {
    modalStore.setTitle("Discard Change");
    modalStore.open(
      <Box>
        <Typography fontSize="small">
          This action will discard all change are you made in this page
        </Typography>
        <Stack mt={2} direction="row" justifyContent="flex-end" gap={2}>
          <Button onClick={modalStore.close} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => {
              history.push("/templates");
              modalStore.close();
            }}
            variant="contained"
            color="error"
          >
            Discard
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <Box
      ml={panelOpen ? "300px" : 0}
      sx={{ transition: "margin-left .3s ease-out" }}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DashboardLayoutHeader
          pageTitle={`${isUpdate ? "Update" : "Create"} Template`}
          breadcrumbs={[
            { label: "templates", href: "/templates" },
            { label: "list", href: "/templates" },
            { label: "form", href: "/templates/form" },
          ]}
        >
          <Stack>
            {/* <table>
              <tbody>
                <tr>
                  <td>
                    <Typography color="inherit" fontWeight={600} fontSize="small">
                      Created At
                    </Typography>
                  </td>
                  <td>
                    <Typography color="GrayText" fontSize="small">
                      {templateDetail?.createdOn
                        ? format(new Date(templateDetail.createdOn), "dd MMM yyyy")
                        : "-"}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography color="inherit" fontWeight={600} fontSize="small">
                      Updated At
                    </Typography>
                  </td>
                  <td>
                    <Typography color="GrayText" fontSize="small">
                      {templateDetail?.modifiedOn
                        ? format(new Date(templateDetail.modifiedOn), "dd MMM yyyy")
                        : "-"}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table> */}
            <Stack mt={2} direction="row" gap={2}>
              <Button
                type="button"
                onClick={onCancel}
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitForm}
                variant="contained"
                type="button"
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </DashboardLayoutHeader>
        <Box display="flex" gap={2}>
          <Box display="flex" flexDirection="column" gap={2} flex={1}>
            <TextField
              value={values.name}
              error={!!errors.name}
              helperText={errors.name}
              onChange={handleChange}
              name="name"
              label="Name"
              fullWidth
            />
            <DNDController setPanelOpen={setPanelOpen} panelOpen={panelOpen} />
            {!!values.components && (
              <FormHelperText error>{values.components}</FormHelperText>
            )}
            <Stack alignItems="flex-end">
              <IconButton
                color="inherit"
                onClick={() => setPanelOpen((prev) => !prev)}
              >
                <SpaceDashboardRounded />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </form>
      {templateDetailLoading && <RkdBackdrop />}
    </Box>
  );
};

export default observer(TemplateFormPage);
