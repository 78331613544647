import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import SitemapSelectPage from "./SitemapSelectPage";
import { useFormik } from "formik";
import * as yup from "yup";
import SitemapSelectParent from "./SitemapSelectParent";

interface SitemapModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SitemapModal = ({ onClose, isOpen }: SitemapModalProps) => {
  const {
    createSitemap,
    getSitemapGrid,
    updateSitemap,
    editedSitemap,
    setEditedSitemap,
    getSitemapTree,
  } = useStore().sitemapStore;

  const {
    handleChange,
    values,
    errors,
    handleSubmit,
    resetForm,
    isSubmitting,
  } = useFormik({
    validationSchema: yup.object({
      pageId: yup.string().required(),
      parentId: yup.string(),
    }),
    initialValues: {
      pageId: editedSitemap?.pageId ?? "",
      parentId: editedSitemap?.parentId ?? "",
    },
    onSubmit: (values) => {
      if (!!editedSitemap) {
        updateSitemap(editedSitemap.id, {
          ...values,
          parentId: !!values.parentId ? values.parentId : null,
        }).then(() => {
          onClose();
          resetForm();
          getSitemapGrid();
          getSitemapTree();
          setEditedSitemap(null);
        });
      } else {
        createSitemap({
          ...values,
          parentId: !!values.parentId ? values.parentId : null,
        }).then(() => {
          onClose();
          resetForm();
          getSitemapGrid();
          getSitemapTree();
        });
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      sx={{ ".MuiPaper-root": { maxWidth: 500, width: "100%" } }}
      open={isOpen || !!editedSitemap}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle>
          <Typography variant="h3" fontSize={20} fontWeight={600} color="black">
            {!!editedSitemap ? "Edit" : "Add"} Sitemap
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, gap: 1 }}>
            <SitemapSelectParent
              error={errors.parentId}
              value={values.parentId}
              handleChange={handleChange}
            />
            <SitemapSelectPage
              error={errors.pageId}
              value={values.pageId}
              handleChange={handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              onClose();
              setEditedSitemap(null);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default observer(SitemapModal);