import { Box, Grid, Typography, Link as MLink, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../../app/config/theme';
import RkdForm from '../../app/component/form/RkdForm';
import RkdTextInput from '../../app/component/form/RkdTextInput';
import { IForgotPasswordValue } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import { Link } from 'react-router-dom';


const initialValuesLogin: IForgotPasswordValue = {
  emailOrUsername: '',
}

const validationSchema = Yup.object({
  emailOrUsername: Yup.string().required(),
});


export const ForgotPasswordPage = () => {  
  const { forgotPassword } = useStore().accountStore;
  const { show } = useStore().snackbarStore;
  return (
    <>
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Forgot Password | Nusantara Regas</title>
      </Helmet>
      <CssBaseline />
      <Grid container sx={{ height: '100vh' }}>        
        <Grid item xs={5}>
          <Box sx={{ height:'100%', display: 'flex', alignItems: 'center'  }}>
            <Box sx={{ m:'auto', width: '50%', display: 'flex', flexDirection: 'column', justifyContent:'center' }}>
              <Box display="flex" justifyContent="space-between" marginBottom={2}>
                <img src='/assets/images/logo-color.png' alt='Logo Nusantara Regas' style={{ width: '300px', objectFit:'cover', margin: 'auto' }} />
              </Box>
              <Typography fontSize={20} fontWeight={600} color="black" mb={2}>Forgot Password</Typography>
              <RkdForm initialValues={initialValuesLogin} validationSchema={validationSchema} buttonSubmitText="Submit"
                submitAction={(value) => forgotPassword(value).then(() => show('success', 'Reset password berhasil, password akan dikirim ke email'))}
              >
                <RkdTextInput name="emailOrUsername" type="text" label="Email or Username" />
                <Stack alignItems="flex-end" mb={2} width="full">
                  <Link to="/auth/login">
                    <MLink>Back to login</MLink>
                  </Link>
                </Stack>
              </RkdForm>
            </Box>
          </Box>
        </Grid>   
        <Grid item xs={7}>
          <Box sx={{ height:'100%', }}>
            <img src='/assets/images/bg-login.jpg' alt='Hero' style={{ width:'100%', height: '100%', objectFit:'cover' }} />
          </Box>
        </Grid>
      </Grid>      
    </ThemeProvider>
    </>
  );
};
