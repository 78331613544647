import { Box, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material"
import { IPagePublishDetail } from "../../app/models/pagePublish"
import { Dispatch, SetStateAction } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface PagePublishDetailFormProps {
  detail: IPagePublishDetail;
  setSchedule: Dispatch<SetStateAction<Date | null>>;
  schedule: Date | null;
}

export const PagePublishDetailForm = ({ detail, setSchedule, schedule }: PagePublishDetailFormProps) => {
  return (
    <Box>
      <FormControlLabel
        control={<Checkbox onChange={(e) => e.currentTarget.checked ? setSchedule(new Date()) : setSchedule(null)} />}
        label="Set scheduler"
        checked={!!schedule}
      />
      <br />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {schedule ? <DateTimePicker onChange={(date) => setSchedule(date)} renderInput={(params) => <TextField {...params} />} value={schedule} /> : null}
      </LocalizationProvider>
    </Box>
  )
}