import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect } from "react";
import { PagePayload } from "../../../app/models/page";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";

interface PageFormTemplateFormItemTimelineProps {
  handleChange: (e: SelectChangeEvent<any>) => void;
  values: PagePayload['pageComponentAttributesValue']
  error: string;
  componentAttr: any;
  name: string;
  disabled?: boolean;
}
export const PageFormTemplateFormItemTimeline = observer(({
  handleChange,
  values,
  error,
  componentAttr,
  name,
  disabled
}: PageFormTemplateFormItemTimelineProps) => {
  const { getList, list, listLoading } = useStore().timelineStore
  
  useEffect(() => {
    getList()
  }, [getList])
  
  return (
    <FormControl fullWidth>
      <InputLabel size='normal' id="demo-simple-select-timeline" required>Timeline</InputLabel>
      <Select
        labelId="select-timeline-label"
        id="select-timeline"
        value={values.find((i) => i.componentAttributeId === componentAttr.componentAttributesId)?.value as any ?? ''}
        label="Timeline"
        name={name}
        disabled={listLoading || disabled}
        onChange={handleChange}
        error={!!error}
        size={'medium'}
      >
        {list.map(item => (
          <MenuItem key={item.id} value={item.id.toLowerCase()}>{item.title}</MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
});
