import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { RichTextEditor } from "../../app/component/RichTextEditor";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";

export const PostFormPage = () => {
  const {
    accountStore: { account, getUser },
    commonStore,
  } = useStore();

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    commonStore.setTitlePage("Add Post");
  });

  return (
    <>
      <DashboardLayoutHeader
        pageTitle="Create Post"
        breadcrumbs={[{ label: "post", href: "/post" }, { label: "create", href: "/form" }]}
      />
      <Box display="flex" gap={2}>
        <Box flex={1} display="flex" flexDirection="column" gap={2}>
          <TextField label="Title" fullWidth />
          <RichTextEditor />
        </Box>
        <Box minWidth={"200px"} display={"flex"} >
          <Divider variant="middle" orientation="vertical" sx={{ height: '100%' }} />
          <List disablePadding sx={{ pl: 2, flex: 1 }}>
            <ListItemCollapse label="Category">
              s
            </ListItemCollapse>
          </List>
        </Box>
      </Box>
    </>
  );
};

const ListItemCollapse = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen((prev) => !prev)}>
        {/* <ListItemIcon>
            <Inbox />
          </ListItemIcon> */}
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};
