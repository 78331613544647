import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import * as yup from 'yup';
import { useStore } from "../../app/stores/store";
import CategorySelect from "./CategorySelect";
import { ICategory, ICategoryPayload } from "../../app/models/category";

interface CreateCategoryModalProps {
  edited?: ICategory;
  defaultParentId?: string;
  callback?: () => void;
}

export const CreateCategoryModal = ({ edited, defaultParentId, callback }: CreateCategoryModalProps) => {
  const { modalStore, contentTypeCategoryStore } = useStore()
  const { createCategory, getGrid, updateCategory } = contentTypeCategoryStore
  
  const formik = useFormik({
    initialValues: {
      name: edited?.name ?? '',
      parentId: defaultParentId ? defaultParentId : edited?.parentId ?? '',
      isCategory: true,
    },
    onSubmit: async (values) => {
      try {
        let payload = {}
  
        if (values.parentId.length === 0) {
          payload = { ...values, parentId: null }
        } else {
          payload = values
        }
        
        if (edited) {
          await updateCategory(edited.id, payload as ICategoryPayload)
            .then(callback)
        } else {
          await createCategory(payload as ICategoryPayload)
            .then(callback)
        }
  
        modalStore.close()
        formik.resetForm()
        getGrid()
      } catch (e) {
        throw e;
      }
    },
    validationSchema: yup.object({
      parentId: yup.string(),
      name: yup.string().required(),
      isCategory: yup.boolean().oneOf([true], 'Must be category')
    }),
    enableReinitialize: true,
  })
  const { handleChange, handleSubmit, values, errors, isSubmitting } = formik
  
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ maxWidth: '500px', width: '300px' }}>
      <Typography fontWeight={600} fontSize={20} color="black" mb={2}>{edited ? 'Edit' : 'Create'} category</Typography>
      <Stack mb={2} gap={2}>
        <TextField
          name="name"
          required
          label="Name"
          onChange={handleChange}
          value={values.name}
          error={!!errors.name}
          helperText={errors.name}
        />
        <CategorySelect formik={formik} />
      </Stack>
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <Button color="inherit" type="button" onClick={() => modalStore.close()}>Cancel</Button>
        <LoadingButton loading={isSubmitting} variant="contained" type="submit">Save</LoadingButton>
      </Stack>
    </Box>
  )
}