import { Helmet } from "react-helmet"

export const CategoryPage = () => {
  return (
    <>
      <Helmet>
        <title>Category | Nusantara Regas</title>
      </Helmet>
    Category</>
  )
}