import { Backdrop, Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

export const SessionPage = observer(() => {
  const { editSession, getSession, session, sessionLoading } = useStore().sessionStore

  const { handleSubmit, handleChange, errors, values } = useFormik({
    initialValues: {
      session: session ?? null,
    },
    onSubmit: async (values) => {
      await editSession(values)

      window.localStorage.removeItem("SessionCountDown");
      window.location.reload()
    },
    validationSchema: yup.object().shape({
      session: yup.number().required(),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    getSession()
  }, [getSession])
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Dashboard",
            href: "/",
          },
          { label: "Session", href: "#" },
        ]}
        pageTitle="Session"
      />
      <Stack
        maxWidth={500}
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <TextField
          value={values.session}
          error={!!errors.session}
          label="Period"
          name="session"
          type="number"
          onChange={handleChange}
          helperText={
            errors.session ||
          "Period ditulis dalam jam, untuk menentukan berapa lama sesi akan dihentikan"
          }
        />
        <Button variant="contained" type="submit">Submit</Button>
      </Stack>
      {sessionLoading && (
        <Backdrop open sx={{ zIndex: 9999999 }}>
          <CircularProgress />
        </Backdrop>
      )}
    </Box>
  );
});
