import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useStore } from "../../app/stores/store"
import { useEffect } from "react"


interface SitemapSelectPageProps {
  handleChange: (event: SelectChangeEvent<any>) => void;
  value: string;
  error?: string;
}

const SitemapSelectPage = ({ handleChange, value, error }: SitemapSelectPageProps) => {
  const { dropdownPage, dropdownPageLoading, getDropdownPage } = useStore().sitemapStore

  useEffect(() => {
    getDropdownPage()
  }, [getDropdownPage])
  
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label" required>Page</InputLabel>
      <Select
        labelId="select-page-label"
        id="select-page"
        value={value}
        label="Page"
        name="pageId"
        disabled={dropdownPageLoading}
        onChange={handleChange}
        error={!!error}
      >
        {dropdownPage.map(item => (
          <MenuItem key={item.value} value={item.value.toLowerCase()}>{item.text}</MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default observer(SitemapSelectPage)