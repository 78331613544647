import { Box, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import { Helmet } from 'react-helmet';
import { PageChart } from './PageChart';
import { ContentTypeChart } from './ContentTypeChart';
import DashboardLayoutHeader from '../../app/component/layout/DashboardLayoutHeader';


function Home() {
    const { accountStore: { account, getUser }, commonStore } = useStore();

    useEffect(() => {
        getUser();
    }, [getUser]);

    useEffect(() => {
        commonStore.setTitlePage('Home Page');
    });

    return (
        <>
            <Helmet>
                <title>Dashboard | Nusantara Regas</title>
            </Helmet>
            <DashboardLayoutHeader
                breadcrumbs={[
                    {
                        label: 'Dashboard',
                        href: '/'
                    }
                ]}
                pageTitle='Dashboard'
            />
            <Stack sx={{ flexDirection: { sx: 'column', md: 'row' } }} gap={2}>
                <Box flex={1}>
                    <ContentTypeChart />
                </Box>
                <Box flex={1}>
                    <PageChart />
                </Box>
            </Stack>
        </>
    );
}

export default observer(Home);
