import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, SubIcon } from "../../config/menu";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { Roles } from "../../config/enum";

const MenuList = ({ menu }: { menu: Menu[] }) => {
  const { account } = useStore().accountStore;
  const { getDropdown, contentTypeMasterDropdown } =
    useStore().contentTypeMasterStore;

  // const displayedMenu = menu.filter((menu) => {
  //   const allowedPermissions =
  //     menu.permissions?.filter((permission) => {
  //       if (!permission) return permission;
  //       else {
  //         return (
  //           account?.roles.includes(permission.role) &&
  //           permission.permission !== "hidden"
  //         );
  //       }
  //     }) ?? [''];

  //   return allowedPermissions.length > 0;
  // });

  return (
    <List>
      {menu?.map((item: any, itemX: any) => (
        <MenuItem menuItem={item} key={itemX} />
      ))}
    </List>
  );
};

export default observer(MenuList);

function MenuItem({ menuItem }: { menuItem: Menu }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { account } = useStore().accountStore;

  const isSelected = () => {
    if (menuItem.submenu) {
      const resolver = (submenu: Menu[]) => {
        return submenu.find((i) => location.pathname.includes(i.route!));
      };

      const result = menuItem.submenu?.map((i) => {
        if (!i.submenu) return false;
        return !!resolver(i.submenu);
      });

      return result?.includes(true);
    } else {
      return location.pathname === menuItem.route!;
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItemButton
        onClick={handleClick}
        selected={isSelected()}
        sx={{
          borderRadius: ({ shape }) => shape.borderRadius + "px",
          ":hover": {
            "&.Mui-selected": {
              backgroundColor: "#025c9c",
            },
          },
          ".MuiTypography-root": {
            color: "white !important",
          },
          ".MuiSvgIcon-root": {
            color: "white !important",
          },
          "&.Mui-selected": {
            backgroundColor: "#025c9c",
            // `${
            //   !!menuItem.submenu ? palette.primary.light : "transparent"
            // } !important`,
            // ".MuiTypography-root": {
            //   color: "black !important",
            //   fontWeight: `${!!menuItem.submenu ? 700 : 500} !important`,
            // },
            // ".MuiSvgIcon-root": {
            //   color: ({ palette }) => `${palette.primary.main} !important`,
            // },
          },
        }}
        component={menuItem.route ? Link : "div"}
        to={menuItem.route}
      >
        <ListItemIcon sx={{ minWidth: "30px !important" }}>
          {menuItem.icon}
        </ListItemIcon>
        <ListItemText primary={menuItem.title} />
        {!!menuItem.submenu && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {menuItem.submenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuList menu={menuItem.submenu} />
        </Collapse>
      )}
    </Fragment>
  );
}
