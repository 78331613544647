import { useDraggable } from "@dnd-kit/core";
import { IComponent } from "../../../app/models/component";
import { DRAGGABLE } from "../helper/constant";
import { DraggableItem } from "./DraggableItem";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

interface DraggableContainerItemProps {
  item: IComponent;
}

export const DraggableContainerItem = observer(({ item }: DraggableContainerItemProps) => {
  const { dndItems } = useStore().dndTemplateStore
  
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: item.id,
    data: {
      dndType: DRAGGABLE,
      ...item,
    },
  });

  const disabled = !!dndItems.find(i => i.id === item.id)
  
  return (
    <DraggableItem
      ref={setNodeRef}
      item={item}
      disabled={disabled}
      {...attributes}
      {...listeners}
    />
  );
});
