export enum FileType {
  Image = "image/*",
  Audio =  "audio/*",
  Vieo = "video/*" ,
  Document = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
} ;

export enum sex {
  Male = 'Laki - Laki',
  Female = 'Perempuan'
}

export enum Roles {
  Superadmin = "Superadmin",
  Admin = "Admin",
  Creator = "Creator",
  Approver = "Approver",
  Publisher = "Publisher",
}

export enum ComponentAttributeType {
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  IMAGE = "IMAGE",
  RICH_TEXT = "RICH_TEXT",
  VIDEO = 'VIDEO',
  NUMBER = 'NUMBER',
  FILE = 'FILE'
}

export enum ContentTypeName {
  Article = 'Article',
  Books = 'Books',
  Announcement = 'Announcement',
  Category = 'Category',
}

export enum MediaType {
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  VIDEO = 'VIDEO',
}

export enum ApprovalResponseType {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PublishStatus {
  PUBLISHED = 'PUBLISHED',
  ON_SCHEDULLED = 'ON_SCHEDULLED',
}

export enum PageStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  ON_SCHEDULLED = 'ON_SCHEDULLED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED'
}

export enum ContentTypeStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  ON_SCHEDULLED = 'ON_SCHEDULLED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED'
}

// export const displayText = (role: Roles) => {
//   switch (role)
//   {
//     case Roles.Superadmin: return "Super Administrator";
//     case Roles.Admin: return "Administrator";
//     default: return "";
//   }
// }

//Notes: yang dikirim di db keys nya

export function enumToArray(obj: any) {
  return Object.keys(obj).map((value) => ({ value , text: obj[value] }));
}  

export const enumMap = <T>(obj: {}, callbackFn: (key: string, value: string, index: number) => T): T[] => {
    // const enumValues: string[] = Object.values(obj);
    return Object.keys(obj).map<T>(
        (k, i) => callbackFn(k, k, i)
    )
}
