import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConfirmSession } from "./ConfirmSession";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";

function convertHoursToMilliseconds(hours: number) {
  return hours * 60 * 60 * 1000;
}

function getCountDownStorage() {
  return window.localStorage.getItem("SessionCountDown");
}

function setCountDownStorage(value: string) {
  window.localStorage.setItem("SessionCountDown", value);
}

function removeCountDownStorage() {
  window.localStorage.removeItem("SessionCountDown");
}

const oneMinute = 60000;

export const CountDown = observer(({ children }: { children: ReactNode }) => {
  const { setToken } = useStore().commonStore;
  const { session, sessionLoading } = useStore().sessionStore;
  const [open, setOpen] = useState(false);
  const [countDown, setCountDown] = useState<number>(10);
  const intervalRef = useRef() as any;

  const defaultCountDown = useMemo(() => convertHoursToMilliseconds(session), [session])

  const initiateInterval = useCallback(() => {
    if (sessionLoading) return
    if (!session) return
    
    const value = getCountDownStorage();

    if (!value) {
      setCountDownStorage(String(defaultCountDown));
    } else {
      setCountDownStorage(value);
      setCountDown(Number(value));
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    
    intervalRef.current = setInterval(() => {
      const value = getCountDownStorage();

      const newCount = Number(value) - oneMinute;

      setCountDownStorage(String(newCount));
      setCountDown(newCount);
    }, oneMinute);
  }, [defaultCountDown, session, sessionLoading]);

  useEffect(() => {
    initiateInterval();
  }, [initiateInterval]);

  useEffect(() => {
    if (countDown <= 0) {
      removeCountDownStorage();
      setOpen(true);
      clearInterval(intervalRef.current);
    }
  }, [countDown]);

  return (
    <>
      {children}
      <ConfirmSession
        open={open}
        callback={(res) => {
          if (!res) return;

          setOpen(false);
          setToken(res.token, res.refreshToken);
          initiateInterval();
        }}
      />
    </>
  );
});
