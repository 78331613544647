import { Box, Button, CircularProgress, IconButton, Stack, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "../../utils/useQuery";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useMemo } from "react";
import { ContentTypeForm } from "./components/ContentTypeForm";
import { useFormik } from "formik";
import * as yup from "yup";
import { history } from "../..";
import SelectLanguage from "../page/components/SelectLanguage";
import ContentTypeSelectCategory from "./components/ContentTypeSelectCategory";
import { Add } from "@mui/icons-material";
import { CreateCategoryModal } from "../contentTypeCategory/CreateCategoryModal";

const ContentTypeFormPage = () => {
  const { id, contentTypeId } = useParams();
  const query = useQuery();
  const contentTypeName = query.get("name");
  const languageId = query.get("languageId");
  const disabled = query.get("disabled");

  const { language } = useStore().languageStore;
  const { categoryList, getCategoryList } = useStore().contentTypeStore
  const { snackbarStore, modalStore } = useStore();
  const { isSuperAdmin, isCreator } = useStore().accountStore
  const {
    getDetail: getDetailMaster,
    detail: detailMaster,
    detailLoading,
    resetDetail: resetDetailMaster
  } = useStore().contentTypeMasterStore;
  // const { detail, getDetail, resetDetail } = useStore().contentTypeStore;

  const {
    create,
    getGrid,
    detail: ctDetail,
    getDetail: getCtDetail,
    resetDetail: resetCtDetail,
    detailLoading: ctDetailLoading,
    update,
  } = useStore().contentTypeStore;

  const initialValues: { [key: string]: string } = useMemo(() => {
    if (detailMaster) {
      return detailMaster?.contentTypeAttributes?.map((i) => {
        if (ctDetail) {
          const temp = detailMaster?.contentTypeAttributes?.reduce((acc, item) => {
            const currentDetail = ctDetail?.contentTypeValues?.find(
              (ct) => {
                return ct?.contentTypeAttributeId === item.id
              }
            );

            const value =
              (currentDetail?.type === "IMAGE"
                ? currentDetail.fileUrl
                : currentDetail?.contentTypeLanguageValue) ??
              ctDetail.contentTypeValues.find(
                (ct) => ct.contentTypeAttributeId === item.id
              )?.contentTypeLanguageValue;

            return { ...acc, [item.name]: value };
          }, {})

          return ({
            ...temp,
            categoryId: ctDetail.categoryId,
            name: ctDetail?.contentTypeValues?.[0]?.contentTypeLanguageValue
          })
        } else {
          return { [i.name]: "", categoryId: '', name: '' }
        }
      }
      )[0];
    } else {
      return {}; 
    }
  }, [detailMaster, ctDetail]);
  
  const validationSchema = useMemo(() => yup.object({
    formType: yup.string().oneOf(['DRAFT', 'SUBMITTED']),
    categoryId: categoryList.length > 0 ? yup.string().required('Category is required field') : yup.string().nullable(),
    name: yup.string().required(),
    ...Object.keys(initialValues)?.map((item) => ({
      [item]: yup.string(),
    }))[0]
  }), [categoryList.length, initialValues]);

  const handleSubmit = async (values: any, status: "SUBMITTED" | "DRAFT") => {
    const { name, categoryId, formType, ...restValues } = values;

    let payload: any = {
      contentTypeName: contentTypeName,
      name: name,
      contentTypeValueMeta: [],
      contentTypeValues: Object.keys(restValues)?.map((item) => ({
        contentTypeAttributeId:
          detailMaster?.contentTypeAttributes.find((i) => i.name === item)
            ?.id ?? "",
        contentTypeAttributeSelect: [],
        contentTypeLanguageValue: restValues[item],
      })),
      languageId: languageId,
      status,
    };

    if (categoryList.length > 0) {
      payload.categoryId = categoryId
    }

    if (!!contentTypeId) {
      return await update(contentTypeId, payload).then(() =>
        snackbarStore.show("success", `${contentTypeName} updated successfully`)
      );
    } else {
      return await create(payload).then((res) => {
        snackbarStore.show("success", `${contentTypeName} created successfully`)
        history.push(`/content-types/${id}/form/${res}?name=${contentTypeName}&languageId=${language[0].id}`)
      })
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!contentTypeName) return;

      await handleSubmit(values, values.formType as any)
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!languageId) {
      if (Array.isArray(language) && !!language.length) {
        contentTypeId
          ? history.push(
              `/content-types/${id}/form/${contentTypeId}?name=${contentTypeName}&languageId=${language[0].id}&disabled=${disabled === 'true' ? 'true' : null}`
            )
          : history.push(
              `/content-types/${id}/form?name=${contentTypeName}&languageId=${language[0].id}&disabled=${disabled === 'true' ? 'true' : null}`
            );
      }
    }
  }, [languageId, language, disabled, id, contentTypeId, contentTypeName]);

  useEffect(() => {
    if (!id) return;

    getDetailMaster(id);

    return () => {
      resetDetailMaster()
    }
  }, [getDetailMaster, id, resetDetailMaster]);

  useEffect(() => {
    if (!contentTypeId || !languageId) return;

    getCtDetail(contentTypeId, languageId);

    return () => {
      resetCtDetail()
    }
  }, [contentTypeId, getCtDetail, languageId, resetCtDetail]);

  useEffect(() => {
    // const firstKey = Object.keys(formik.values)[0];
    const currentValue = formik.values.Title ?? '';
    console.log(currentValue);
    formik.setFieldValue("name", currentValue);
  }, [formik.values]);

  useEffect(() => {
    if (!contentTypeId) {
      formik.resetForm()
    }
  }, [contentTypeId, initialValues])

  useEffect(() => {
    getCategoryList(contentTypeName!)
  }, [contentTypeName, getCategoryList])

  const handleAddCategory = () => modalStore.open(<CreateCategoryModal callback={() => getCategoryList(contentTypeName!)} defaultParentId={id} />)
  
  const canEdit = (isSuperAdmin || isCreator) && disabled !== 'true'
  
  return (
    <Box>
      <DashboardLayoutHeader
        pageTitle={(canEdit ? (contentTypeId ? "Edit " : "Add ") : '') + (canEdit ? contentTypeName : formik.values?.name)}
        breadcrumbs={[
          {
            label: "content types",
            href: `/content-types/list/${id}?name=${contentTypeName}`,
          },
          {
            label: contentTypeName?.toLowerCase() ?? "",
            href: `/content-types/${id}/form?name=${contentTypeName}`,
          },
        ]}
      >
        <Stack gap={2}>
          <Stack direction="row" gap={1}>
            <DashboardLayoutHeader.Button
              color="inherit"
              onClick={() =>
                history.push(
                  `/content-types/list/${id}?name=${contentTypeName}`
                )
              }
            >
              {canEdit ? 'Cancel' : 'Back'}
            </DashboardLayoutHeader.Button>
            <DashboardLayoutHeader.Button
              loading={formik.isSubmitting}
              onClick={() => {
                formik.setFieldValue('formType', 'DRAFT').then(() => {
                  formik.submitForm()
                })
              }} 
              disabled={!canEdit}
              variant="outlined"
            >
              Save as Draft
            </DashboardLayoutHeader.Button>
            <DashboardLayoutHeader.Button
              loading={formik.isSubmitting}
              onClick={() => {
                formik.setFieldValue('formType', 'SUBMITTED').then(() => {
                  formik.submitForm()
                })
              }}
              disabled={!canEdit}
              variant="contained"
            >
              Save
            </DashboardLayoutHeader.Button>
          </Stack>
          <SelectLanguage
            value={languageId ?? ""}
            onChange={(v) =>
              contentTypeId
                ? history.push(
                    `/content-types/${id}/form/${contentTypeId}?name=${contentTypeName}&languageId=${v}`
                  )
                : history.push(
                    `/content-types/${id}/form?name=${contentTypeName}&languageId=${v}`
                  )
            }
          />
          {categoryList.length > 0 ? (
            <Stack direction="row" alignItems="flex-start" gap={2}>
              <ContentTypeSelectCategory
                disabled={!canEdit}
                value={formik.values?.categoryId ?? ''}
                handleChange={formik.handleChange}
                error={formik.errors?.categoryId}
                contentTypeName={contentTypeName!}
              />
              <IconButton disabled={!canEdit} onClick={handleAddCategory} color="primary" size="small">
                <Add />
              </IconButton>
            </Stack>
          ) : (
            <Stack direction="row" gap={2}>
              <TextField fullWidth disabled placeholder={contentTypeName + ' have no categories'} size="small" />
              <IconButton disabled={!canEdit} onClick={handleAddCategory} color="primary" size="small">
                <Add />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </DashboardLayoutHeader>
      {detailLoading || ctDetailLoading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        detailMaster && (
          <ContentTypeForm
            isEdit={!!contentTypeId}
            disabled={!canEdit}
            formik={formik}
            detail={detailMaster}
            ctDetail={ctDetail}
          />
        )
      )}
    </Box>
  );
};

export default observer(ContentTypeFormPage);
