import { Box, Button, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useStore } from "../../app/stores/store";
import { MediaType } from "../../app/config/enum";
import { observer } from "mobx-react-lite";
import { AddMediaResponse } from "../../app/models/media";
import { getFileExtension } from "../../utils/getFileExtentions";
import { getFileNameWithoutExtension } from "../../utils/getFileNameWithoutExtention";
import { LoadingButton } from "@mui/lab";
import { Article } from "@mui/icons-material";

interface AddMediaProps {
  callback?: (e: AddMediaResponse) => void;
}

const AddMedia = ({ callback }: AddMediaProps) => {
  const { createMedia, getFileGrid, getImageGrid, getVideoGrid } =
    useStore().mediaStore;
  const { show } = useStore().snackbarStore;
  const { close } = useStore().modalStore;
  const [loading, setLoading] = useState(false);
  const [fileProps, setFileProps] = useState<{
    file: File;
    fileName: string;
    type: MediaType;
    ext: string;
  }>();

  const isImage = (file: File) => file.type.includes("image");
  const isVideo = (file: File) => file.type.includes("video");

  const handlePickMedia = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    
    let type: MediaType;

    if (!file) return;

    const fiveteenMb = 15 * 1024 * 1024

    if (file.size > fiveteenMb) {
      return show('error', "file tidak boleh lebih dari 15mb")
    }
    
    if (isImage(file)) {
      type = MediaType.IMAGE;
    } else if (isVideo(file)) {
      type = MediaType.VIDEO;
    } else {
      type = MediaType.FILE;
    }

    setFileProps({
      file,
      type,
      ext: getFileExtension(file.name) ?? "",
      fileName: getFileNameWithoutExtension(file.name),
    });
  };

  const handleAddMedia = async () => {
    setLoading(true);
    try {
      if (!fileProps) return;

      const formData = new FormData();

      formData.append("type", fileProps.type);
      formData.append("file", fileProps.file);
      formData.append("fileName", fileProps.fileName);

      const res = await createMedia(formData);

      callback && (await callback(res));
      close();
      setFileProps(undefined);

      if (isImage(fileProps.file)) {
        getImageGrid();
      } else if (isVideo(fileProps.file)) {
        getVideoGrid();
      } else {
        getFileGrid();
      }
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return fileProps ? (
    <Box
      sx={{
        backgroundColor: "white",
        border: ({ palette }) => `1px solid ${palette.divider}`,
        borderRadius: 2,
        mt: 1.5,
      }}
    >
      <Typography
        fontWeight={600}
        color="inherit"
        mt={-1.5}
        ml={1.5}
        sx={{ backgroundColor: "white", px: 0.5, width: "fit-content" }}
      >
        File upload
      </Typography>
      <Stack spacing={2} p={2}>
        <Button disabled variant="outlined" color="inherit">
          {fileProps.type === MediaType.FILE ? (
            <Article fontSize="large" />
          ) : (
            <img
              alt="preview"
              src={URL.createObjectURL(fileProps.file)}
              width={150}
              height={150}
            />
          )}
        </Button>
        <TextField
          label="File name"
          value={fileProps.fileName}
          InputLabelProps={{
            shrink: !!fileProps.fileName,
          }}
          InputProps={{
            endAdornment: fileProps.ext,
          }}
          onChange={(e) =>
            setFileProps({ ...fileProps, fileName: e.target.value })
          }
        />
        <Stack justifyContent="flex-end" direction="row" spacing={2}>
          <Button
            disabled={loading}
            onClick={() => setFileProps(undefined)}
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={loading}
            loading={loading}
            onClick={handleAddMedia}
            variant="contained"
          >
            Upload
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <Stack flexDirection="column">
      <Button component="label" variant="contained">
        Add media
        <input onChange={handlePickMedia} type="file" hidden />
      </Button>
      <FormHelperText>Ukuran file max 15MB</FormHelperText>
    </Stack>
  );
};

export default observer(AddMedia);
