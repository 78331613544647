import { forwardRef } from "react";
import { IComponent } from "../../../app/models/component";
import { DragOverlay } from "@dnd-kit/core";
import { DraggableItem } from "./DraggableItem";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

interface DraggableItemOverlayProps {
  item: IComponent;
}

export const DraggableItemOverlay = forwardRef<any, DraggableItemOverlayProps>(
  ({ item }, ref) => {
    return (
      <DragOverlay
        zIndex={99999}
        adjustScale={false}
        modifiers={[restrictToWindowEdges]}
      >
        <DraggableItem isOverlay ref={ref} item={item} />
      </DragOverlay>
    );
  }
);
