import { Box } from "@mui/material"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { ApproveRejectModal } from "./ApproveRejectModal";
import { useState } from "react";
import { ContentTypeApproveRejectTypeValues } from "../../app/models/contentTypeApproval";
import ContentTypeApprovalDataGrid from "./ContentTypeApprovalDataGrid";
import { Helmet } from "react-helmet";

export const ContentTypeApprovalPage = () => {
  const [approveRejectState, setApproveRejectState] = useState<ContentTypeApproveRejectTypeValues | null>(null)
  
  return (
    <Box>
      <Helmet>
        <title>Content Type Approval | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        pageTitle="Content Type Approval"
        breadcrumbs={[
          { label: 'content-types', href: '/content-type/approval' },
          { label: 'approval', href: '/content-type/approval' },
        ]}
      >
      </DashboardLayoutHeader>
      <ContentTypeApprovalDataGrid setApproveRejectState={setApproveRejectState} />
      <ApproveRejectModal approveRejectState={approveRejectState} setApproveRejectState={setApproveRejectState} isOpen={!!approveRejectState} onClose={() => setApproveRejectState(null)} />
    </Box>
  )
}