import { LoadingButton } from "@mui/lab"
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../app/stores/store"

interface DeletePageModalProps {
  id: string
}

const DeletePageModal = ({ id }: DeletePageModalProps) => {
  const { modalStore, pageStore } = useStore()
  const { deletePage, deletePageLoading, getPageGrid } = pageStore;
  
  const handleDelete = () => {
    deletePage(id)
      .then(() => {
        modalStore.close()
        getPageGrid()
      })
  }
  
  return (
    <Box>
      <DialogTitle fontWeight={600} color="black">
        Delete Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to remove this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={modalStore.close} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          loading={deletePageLoading}
          onClick={handleDelete}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}

export default observer(DeletePageModal)