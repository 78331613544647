import { TailwindProvider } from "../../app/component/TailwindProvider";
import { Card6x6 } from "./Card6x6";
import { CarouselHeader } from "./CarouselHeader";
import { Frame2RowsImage } from "./Frame2RowsImage";
import { FrameCardWrapTextImage } from "./FrameCardWrapTextImage";
import { FrameImageTitleDesc } from "./FrameImageTitleDesc";
import { FrameFullImage } from "./FrameTitleFullImage";
import { FrameTitleVideo } from "./FrameTitleVideo";
import { Header01 } from "./Header01";

export const DUMMY_IMG = 'https://images.unsplash.com/photo-1707650042645-f4123aeef0b2?q=80&w=2360&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'

export const TestPage = () => {
  
  

  return (
    <TailwindProvider>
      {/* <FrameFullImage /> */}
      {/* <FrameImageTitleDesc /> */}
      {/* <FrameTitleVideo /> */}
      <FrameCardWrapTextImage />
      {/* <Frame2RowsImage /> */}
      {/* <CarouselHeader /> */}
    </TailwindProvider>
    // <Header01 />
    // <Card6x6 />
  );
};
