import { makeAutoObservable, runInAction } from "mobx";
import { IComponent } from "../models/component";
import agent from "../api/agent";

export class ComponentStore {
  components: IComponent[] = []
  componentsLoading = false;
  
  constructor() {
    makeAutoObservable(this)
  }

  getComponents = async () => {
    this.componentsLoading = true
    
    try {
      const res = await agent.Component.list()
      
      runInAction(() => this.components = res)
    } finally {
      runInAction(() => this.componentsLoading = false)
    }
  }
}