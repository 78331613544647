import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { removeSymbols } from "../../../utils/removeSimbols";
import SelectTemplate from "./SelectTemplate";
import { useEffect } from "react";
import { slugConverter } from "../../../utils/slugConverter";
import { useStore } from "../../../app/stores/store";

interface PageFormProps {
  formik: any;
  disabled?: boolean;
  callbackTemplate: () => void;
}

export const PageForm = ({ disabled, formik: { values, setFieldValue, errors }, callbackTemplate }: PageFormProps) => {
  const { getTemplateDetail } = useStore().templateStore

  useEffect(() => {
    setFieldValue('slug', slugConverter(values.name ?? ''))
  }, [values.name, setFieldValue])

  useEffect(() => {
    if (values.templateId === '00000000-0000-0000-0000-000000000000') return;

    getTemplateDetail(values.templateId)
  }, [values.templateId, getTemplateDetail])
  
  return (
    <Box display="flex" flexDirection="column" gap={2} mb={2}>
      <TextField
        value={values.name}
        onChange={(e) =>
          setFieldValue('name', e.target.value)
        }
        disabled={disabled}
        error={!!errors.name}
        helperText={errors.name}
        label="Title"
        required
        fullWidth
      />
      <TextField
        value={values.slug}
        onChange={(e) =>
          setFieldValue('slug', removeSymbols(e.target.value).toLowerCase())
        }
        disabled={disabled}
        error={!!errors.slug}
        helperText={errors.slug}
        label="Slug"
        required
        fullWidth
      />
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            name="isDefault"
            checked={values.isDefault}
            onChange={(e) => setFieldValue('isDefault', e.target.checked)}
          />
        }
        label="Set is main page"
      />
      <SelectTemplate
        disabled={disabled}
        value={values.templateId}
        onChange={(value) => setFieldValue('templateId', value)}
      />
    </Box>
  );
};
