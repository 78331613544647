import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useStore } from "../../stores/store";
import {
  Stack,
} from "@mui/material";
import AvatarAppBar from "./AvatarAppBar";
import { observer } from "mobx-react-lite";
import { ConfirmSession } from "../../../features/account/ConfirmSession";
import { CountDown } from "../../../features/account/CountDown";

const drawerWidth = 280;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  drawerVariant: 'permanent' | 'temporary'
}

const DashboardLayout = (props: Props) => {
  const { accountStore } = useStore();
  const [notificationRef, setNotificationRef] =
    React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  if (!accountStore.isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <CountDown>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            display: { xs: 'block', sm: 'none' },
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "white",
            boxShadow: "0 0 8px 4px rgba(0,0,0, .05)",
          }}
        >
          <Toolbar>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Box>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Stack direction="row" alignItems="center" gap="16px">
                <AvatarAppBar />
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>

        <Sidebar
          mobileOpen={mobileOpen}
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar sx={{ display: { xs: 'block', sm: 'none' } }} />
          <Outlet />
        </Box>
      </Box>
      {/* <ConfirmSession />  */}
    </CountDown>
  );
};

export default observer(DashboardLayout);
