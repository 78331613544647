import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../app/stores/store"
import { useEffect } from "react"

interface SelectLanguageProps {
  onChange: (v: string) => void
  value: string;
  disabled?: boolean;
}

const SelectLanguage = ({ onChange, value, disabled }: SelectLanguageProps) => {
  const { getList, language } = useStore().languageStore
  
  useEffect(() => {
    getList()
  }, [getList])
  
  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="Language-label">Language</InputLabel>
      <Select
        size="small"
        labelId="Language-label"
        id="demo-simple-select"
        value={value}
        disabled={disabled}
        label="Language"
        onChange={(v) => onChange(v.target.value)}
      >
        {language.map(item => (
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default observer(SelectLanguage)