import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useStore } from "../../../app/stores/store";
import { useQuery } from "../../../utils/useQuery";

interface ContentTypeSelectCategoryProps {
  handleChange: (event: SelectChangeEvent<any>) => void;
  value: string;
  error?: string;
  size?: 'normal' | 'small';
  contentTypeName: string;
  disabled?: boolean;
}

const ContentTypeSelectCategory = ({ handleChange, value, error, size = 'small', contentTypeName, disabled }: ContentTypeSelectCategoryProps) => {
  const { categoryList, categoryListLoading, getCategoryList } = useStore().contentTypeStore
  
  useEffect(() => {
    if (!contentTypeName) return;
    
    getCategoryList(contentTypeName)
  }, [getCategoryList, contentTypeName])
  
  return (
    <FormControl fullWidth>
      <InputLabel size={size} id="demo-simple-select-label" required>Category</InputLabel>
      <Select
        labelId="select-category-label"
        id="select-category"
        value={value}
        label="Category"
        name="categoryId"
        disabled={categoryListLoading || disabled}
        onChange={handleChange}
        error={!!error}
        size={size === 'normal' ? 'medium' : size}
      >
        {categoryList.map(item => (
          <MenuItem key={item.id} value={item.id.toLowerCase()}>{item.name}</MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default observer(ContentTypeSelectCategory)