import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import RkdDataGrid from "../../app/component/dataGrid/RkdDataGrid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IExample } from "../../app/models/example";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import RkdCard from "../../app/component/card/RkdCard";

function ExampleList() {
  const { exampleStore, dialogStore, snackbarStore, commonStore } = useStore();
  const navigate = useNavigate();

  const gridColumns: GridColumns<IExample> = [
    { field: "id", headerName: "ID", width: 300, type: "string" },
    { field: "name", headerName: "Name", width: 250, type: "string" },
    { field: "price", headerName: "Price", width: 100, type: "number" },
    { field: "date", headerName: "Date", width: 250, type: "dateTime" },
    { field: "isReady", headerName: "Ready?", width: 100, type: "boolean" },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          onResizeCapture={() => {}}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
          placeholder={''}
          onResize={() => {}}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => navigate(`/example/edit/${params.row.id}`)}
        />,
        <GridActionsCellItem
          onResizeCapture={() => {}}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
          placeholder={''}
          onResize={() => {}}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() =>
            dialogStore.open({
              title: "Warning",
              description: `Are you sure you want to delete ${params.row.name}?`,
              closeText: "Cancel",
              action: () =>
                exampleStore
                  .deleteExample(params.row.id!)
                  .then(() => {
                    exampleStore.getExampleList();
                    snackbarStore.show(
                      "success",
                      `${params.row.name} deleted successfully`
                    );
                  })
                  .catch(() =>
                    snackbarStore.show(
                      "error",
                      `Failed to delete ${params.row.name}`
                    )
                  ),
              actionText: "Delete",
            })
          }
        />,
      ],
    },
  ];

  useEffect(() => {
    exampleStore.getExampleList();
  }, [exampleStore]);

  useEffect(() => {
    commonStore.setTitlePage("Example Page");
  });

  return (
    <RkdCard title="Data Example" subtitle="">
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/example/create"
      >
        Create New Example
      </Button>
      <RkdDataGrid gridStore={exampleStore} columns={gridColumns} />
    </RkdCard>
  );
}

export default observer(ExampleList);
