import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DeleteRounded, HeightRounded, HideImageRounded } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { IItemValue } from "./DNDController";
import { SORTABLE_ITEM } from "../helper/constant";
import { useStore } from "../../../app/stores/store";
import { renderHtml } from "../../../utils/renderHtml";
import { TailwindProvider } from "../../../app/component/TailwindProvider";

interface SortableDNDItemProps {
  item: IItemValue;
}

export function SortableItem({ item }: SortableDNDItemProps) {
  const { removeDndItem } = useStore().dndTemplateStore;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: item.itemId,
      data: { dndType: SORTABLE_ITEM, ...item },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? .5 : 1,
    cursor: 'auto'
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }} style={style}>
      <Box
        ref={setNodeRef}
        sx={{
          backgroundColor: "rgba(0,0,0,.03)",
        }}
        borderRadius={1}
        {...attributes}
      >
        {!!item.htmlSkeleton ? (
          <TailwindProvider>
            {renderHtml(item.htmlSkeleton ?? '')}
          </TailwindProvider>
          // <Box height={'100px'} width="100%">
            
          // </Box>
        ) : (
          <Stack color="ActiveBorder" p={2} alignItems="center">
            <HideImageRounded />
          </Stack>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          p: 1,
        }}
      >
        <IconButton sx={{ backgroundColor: 'white' }} size="small" onClick={() => removeDndItem(item.itemId)}>
          <DeleteRounded fontSize="small" />
        </IconButton>
        <IconButton sx={{ backgroundColor: 'white' }} size="small" {...listeners}>
          <HeightRounded fontSize="small" />
        </IconButton>
      </Box>
      <Box
        position={"absolute"}
        top={2}
        left={2}
        py={0.2}
        px={0.5}
        sx={{
          backgroundColor: "white",
          borderRadius: ({ shape }) => shape.borderRadius,
        }}
      >
        <Typography fontSize={"10px"}>{item.name}</Typography>
      </Box>
    </Box>
  );
}
