import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { PagePayload } from "../../../app/models/page";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ICategory } from "../../../app/models/category";

interface PageFormTemplateFormItemCategoryProps {
  handleChange: (e: SelectChangeEvent<any>) => void;
  values: PagePayload['pageComponentAttributesValue']
  error: string;
  componentAttr: any;
  contentTypeId: string;
  name: string
  disabled?: boolean;
}
export const PageFormTemplateFormItemCategory = observer(({
  handleChange,
  values,
  error,
  componentAttr,
  contentTypeId,
  name,
  disabled,
}: PageFormTemplateFormItemCategoryProps) => {
  const { getCategoryList } = useStore().contentTypeStore
  const [options, setOptions] = useState<ICategory[]>([])
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false)
  
  const { contentTypeMasterDropdown, getDropdown } = useStore().contentTypeMasterStore
  
  const [contentTypeName, setContentTypeName] = useState<string>()
  
  useEffect(() => {
    const ct = contentTypeMasterDropdown.find(i => i.value.toString() === contentTypeId.toLowerCase())

    setContentTypeName(ct?.text)
  }, [contentTypeMasterDropdown, contentTypeId])
  
  useEffect(() => {
    if (!contentTypeName) return;

    setOptionsLoading(true);
    (async function () {
      try {
        const res = await getCategoryList(contentTypeName)

        setOptions(res)
      } finally {
        setOptionsLoading(false);
      }
    })()
  }, [getCategoryList, contentTypeName])

  useEffect(() => {
    getDropdown()
  }, [getDropdown])
  
  return (
    <FormControl fullWidth>
      <InputLabel size='normal' id="demo-simple-select-label" required>Category</InputLabel>
      <Select
        labelId="select-category-label"
        id="select-category"
        value={values.find((i) => i.componentAttributeId === componentAttr.componentAttributesId)?.value as any ?? ''}
        label="Category"
        name={name}
        disabled={optionsLoading || disabled}
        onChange={handleChange}
        error={!!error}
        size={'medium'}
      >
        {options.map(item => (
          <MenuItem key={item.id} value={item.id.toLowerCase()}>{item.name}</MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
});
