// import { useQuill } from 'react-quilljs';
import ReactQuill from 'react-quill'

import 'quill/dist/quill.snow.css'; 
import { FormControl, InputLabel } from '@mui/material';
import { useState } from 'react';
// import 'quill/dist/quill.bubble.css'; 

export interface RichTextEditorProps {
  name?: string;
  label?: string;
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
}

export const RichTextEditor = ({ name, label, onChange, value, disabled }: RichTextEditorProps) => {
  const [theme, setTheme] = useState('snow')

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <div style={{ width: '100%' }}>
        <ReactQuill 
          readOnly={disabled}  
          theme={theme}
          onChange={onChange}
          value={value}
          style={{
            marginBottom: 47,
            height: 400,
          }}
         />
      </div>
    </FormControl>
  );
};
