import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { IItemValue } from "./DNDController";
import { Box, Typography } from "@mui/material";
import { SortableItem } from "./SortableItem";
import { useMemo } from "react";
import { useDroppable } from "@dnd-kit/core";
import { DROPPABLE_AREA } from "../helper/constant";

export const Droppable = ({ items }: { items: IItemValue[] }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: DROPPABLE_AREA,
    data: { dndType: DROPPABLE_AREA }
  });
  const isEmpty = useMemo(() => Array.isArray(items) && !items.length, [items]);

  return (
    <SortableContext
      id={DROPPABLE_AREA}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <Box
        sx={{
          minHeight: "100px",
          // backgroundColor: ({ palette }) => `rgba(0,0,0,.02)`,
          backgroundColor: ({ palette }) => isOver ? palette.primary.light : `rgba(0,0,0,.02)`,
          border: ({ palette }) => `1px solid ${palette.divider}`,
          borderRadius: ({ shape }) => shape.borderRadius + "px",
          position: "relative",
        }}
        ref={setNodeRef}
      >
        {isEmpty && (
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            Drag and drop component here
          </Typography>
        )}
        {items.map((item) => (
          <SortableItem key={item.itemId} item={item} />
        ))}
      </Box>
    </SortableContext>
  );
};
