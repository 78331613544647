import { observer } from "mobx-react-lite"
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium"
import { useStore } from "../../app/stores/store"
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { DeleteRounded, EditRounded } from "@mui/icons-material"
import { ITimeline } from "../../app/models/timeline"
import { LoadingButton } from "@mui/lab"
import { useEffect } from "react"

const TimelineGrid = () => {
  const { timelineStore, modalStore } = useStore()
  const { grid, getGrid, gridLoading, setTemp, deleteTimeline, deleteLoading } = timelineStore
  
  const handleOpenDeleteModal = (id: string) => {
    modalStore.setTitle("Delete Confirmation");
    modalStore.open(
        <Box>
          <Typography>Are you sure want to delete this item?</Typography>
          <Stack direction="row" gap={2} justifyContent="flex-end" mt={2}>
            <Button onClick={modalStore.close} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              onClick={() =>
                deleteTimeline(id).then(() => {
                  modalStore.close();
                  getGrid();
                })
              }
              color="error"
              variant="contained"
              loading={deleteLoading}
            >
              Delete
            </LoadingButton>
          </Stack>
        </Box>
    );
  };
  
  const setEditedSitemap = (item: ITimeline) => {
    setTemp(item)
  }

  useEffect(() => {
    getGrid()
  }, [getGrid])
  
  return (
    <StyledDataGridPremium
      columns={[
        {
          field: "action",
          headerName: "",
          width: 100,
          renderCell: (params) => (
            <Stack direction="row" gap={1}>
              <Tooltip title="Edit">
                <IconButton color="info" onClick={() => setEditedSitemap(params.row as any)}>
                  <EditRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton color="error" onClick={() => handleOpenDeleteModal(params.row.id)}>
                  <DeleteRounded />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        },
        {
          field: "title",
          headerName: "Title",
          width: 150,
          flex: 1
        },
      ]}
      autoHeight
      rows={grid.data}
      rowCount={grid.rowCount}
      loading={gridLoading}
    />
  )
}

export default observer(TimelineGrid);
