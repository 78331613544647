import { makeAutoObservable, runInAction } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IContentTypeApproval, IContentTypeApprovalLog } from "../models/contentTypeApproval";
import agent from "../api/agent";

export class ContentTypeApprovalStore {
  grid: DataGridResult<IContentTypeApproval[]> = new DataGridResult([], 0)
  gridLoading = false

  logGrid: DataGridResult<IContentTypeApprovalLog[]> = new DataGridResult([], 0)
  logGridLoading = false
  
  params = {
    pageSize: 25,
    currentPage: 0
  }
  
  constructor() {
    makeAutoObservable(this)
  }

  getGrid = async () => {
    this.gridLoading = true
    
    try {
      const res = await agent.ContentTypeApproval.listGrid(this.params)

      runInAction(() => this.grid = res)
    } finally {
      runInAction(() => this.gridLoading = false)
    }
  }
  
  getLogGrid = async (contentTypeValueId: string) => {
    this.logGridLoading = true
    
    try {
      const res = await agent.ContentTypeApproval.logListGrid(contentTypeValueId, this.params)

      runInAction(() => this.logGrid = res)
    } finally {
      runInAction(() => this.logGridLoading = false)
    }
  }

  setParams = (key: keyof typeof this.params, value: number) => {
    this.params[key] = value;
  }
}