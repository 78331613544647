import { Box, IconButton, Stack, Toolbar } from "@mui/material"
import { Close } from "@mui/icons-material"
import { DraggableContainer } from "./DraggableContainer"
import { useStore } from "../../../app/stores/store"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

const Panel = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
  const { getComponents, componentsLoading, components } = useStore().componentStore

  useEffect(() => {
    getComponents()
  }, [getComponents])
  
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        width: '300px',
        background: 'white',
        zIndex: 999,
        ml: open ? 0 : '-300px',
        transition: `margin-left .3s ease-out`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar />
      <Stack alignItems="flex-end" px={2} pt={2}>
        <IconButton size="small" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Stack>
      <Box flex={1} overflow="auto">
        <DraggableContainer items={components} />
      </Box>
    </Box>
  )
}

export default observer(Panel)