import { Box, Stack } from "@mui/material"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"
import { useStore } from "../../app/stores/store"
import AddMedia from "./AddMedia"
import DeleteMediaModal from "./DeleteMediaModal"
import { ViewImageModal } from "./ViewMediaModal"
import { useState } from "react"
import { Helmet } from "react-helmet"
import MediaTab from "./MediaTab"
import { IMedia } from "../../app/models/media"
import { MediaType } from "../../app/config/enum"

export const MediaPage = () => {
  const { modalStore } = useStore()
  const [viewUrl, setViewUrl] = useState<string | null>(null)
  const { editMedia, getVideoGrid, getFileGrid, getImageGrid } = useStore().mediaStore
  
  const openAddMediaModal = () => {
    return modalStore.open(<AddMedia />)
  }

  const handleDeleteMedia = (id: string) => {
    modalStore.open(<DeleteMediaModal id={id} />);
  };

  const handleEditMedia = async (id: string, media: IMedia) => {
    const formData = new FormData();

    formData.append("type", media.type);
    formData.append("file", media.filePath);
    formData.append("fileName", media.nameFile);
    
    await editMedia(id, formData)

    if (media.type === MediaType.FILE) getFileGrid()
    if (media.type === MediaType.VIDEO) getVideoGrid()
    if (media.type === MediaType.IMAGE) getImageGrid()
  }
  
  return (
    <Box>
      <Helmet>
        <title>Media | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        pageTitle="Media"
        breadcrumbs={[{ label: 'media', href: '/medias' }, { label: 'list', href: '/medias' }]}
      >
        {/* <DashboardLayoutHeader.Button onClick={openAddMediaModal} variant="contained">Add Media</DashboardLayoutHeader.Button> */}
      </DashboardLayoutHeader>
      <Stack gap={2}>
        <AddMedia />
        {/* <MediaGrid /> */}
        <MediaTab 
          onEditMedia={handleEditMedia}
          onRemoveMedia={handleDeleteMedia} 
          onClickMedia={(media) => (media.type === 'IMAGE' || media.type === 'VIDEO') ? setViewUrl(media.filePath) : window.open(media.filePath, '_blank')} 
        />
        {/* <MediaTile 
          onRemoveMedia={handleDeleteMedia} 
          onClickMedia={(media) => media.type === 'IMAGE' ? setViewUrl(media.filePath) : window.open(media.filePath, '_blank')} 
        /> */}
      </Stack>
      <ViewImageModal isOpen={!!viewUrl} onCLose={() => setViewUrl(null)} url={viewUrl} />
    </Box>
  )
}

