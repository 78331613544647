import { IContentTypeMasterDetail } from "../../../app/models/contentTypeMaster";
import { getField } from "../../../utils/getField";
import { Stack } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import AddMedia from "../../media/AddMedia";
import MediaTile from "../../media/MediaTile";
import { FormikProps } from "formik";
import { Fragment, useEffect, useState } from "react";
import { IContentTypeDetail } from "../../../app/models/contentType";
import { MediaImage } from "../../media/MediaImage";
import { MediaVideo } from "../../media/MediaVideo";
import { MediaFile } from "../../media/MediaFile";
import { MediaType } from "../../../app/config/enum";
import { IMedia } from "../../../app/models/media";

interface ContentTypeFormProps {
  detail: IContentTypeMasterDetail;
  formik: FormikProps<any>;
  ctDetail: IContentTypeDetail | null;
  disabled?: boolean;
  isEdit: boolean;
}

export const ContentTypeForm = ({ detail, formik, ctDetail, isEdit, disabled }: ContentTypeFormProps) => {
  const { modalStore } = useStore();
  const [preview, setPreview] = useState<{[key: string]: string}>({})
  
  const handlePickMedia = (name: string, media: IMedia) => {
    formik.setFieldValue(name, media.id)
    setPreview(prev => ({...prev, [name]: media.filePath }))
    modalStore.close()
  }
 
  const handleOpenMediaModal = (name: string, type: string) => {
    modalStore.open(
      <Stack gap={2} sx={{ maxHeight: '80vh', minWidth: '80vw', overflow: 'auto' }}>
        <AddMedia 
          callback={(value) => {
            formik.setFieldValue(name, value.id)
            setPreview(prev => ({ ...prev, [name]: value.filePath }))
            modalStore.close()
          }} 
        />
        {type === MediaType.IMAGE && <MediaImage croppable onClickMedia={(media) => handlePickMedia(name, media)} />}
        {type === MediaType.VIDEO && <MediaVideo onClickMedia={(media) => handlePickMedia(name, media)} />}
        {type === MediaType.FILE && <MediaFile onClickMedia={(media) => handlePickMedia(name, media)} />}
      </Stack>
    );
  };

  useEffect(() => {
    if (!isEdit) return;

    Object.keys(formik.values).map(key => {
      return setPreview(prev => ({...prev, [key]: formik.values[key]}))
    })
  }, [formik.values, isEdit])
  
  return (
    <Stack gap={2}>
      {detail?.contentTypeAttributes?.map((item, index) => {
        return (
          <Fragment key={index}>
            {getField({
              disabled: disabled,
              element: item.type as any,
              props: {
                key: index,
                name: item.id,
                label: item.name,
                value: formik.values[item.name],
                preview: preview[item.name],
                onOpenMedia: () => handleOpenMediaModal(item.name, item.type),
                onChange: (data: any) => {
                  switch (item.type) {
                    case 'TEXT':
                    case 'TEXT_AREA':
                      return formik.setFieldValue(item.name, data.target.value)
                    case 'RICH_TEXT':
                      return formik.setFieldValue(item.name, data)
                  }
                }
              },
            })}
          </Fragment>
        )
      })}
      {/* <ContentTypeSelectCategory /> */}
    </Stack>
  );
};
