import { makeAutoObservable, runInAction } from "mobx";
import { DataGridResult, IDataGridParams } from "../models/materialUI/dataGrid";
import { IPageApproval } from "../models/pageApproval";
import agent from "../api/agent";

export class PageApprovalStore {
  approvalGrid: DataGridResult<IPageApproval[]> = new DataGridResult([], 0)
  approvalGridLoading = false
  
  params: IDataGridParams = {
    pageSize: 25,
    currentPage: 0
  }
  
  constructor() {
    makeAutoObservable(this)
  }

  getApprovalGrid = async () => {
    this.approvalGridLoading = true;
    try {
      const res = await agent.PageApproval.listGrid(this.params)
      
      runInAction(() => this.approvalGrid = res)
    } finally {
      runInAction(() => this.approvalGridLoading = false)
    }
  }

  setParams = (key: keyof typeof this.params, v: number) => {
    return this.params[key] = v
  }
}