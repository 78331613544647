import { Box, CircularProgress, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { ContentTypePublishDetailForm } from "./ContentTypePublishDetailForm";
import { PublishStatus } from "../../app/config/enum";
import { history } from "../..";
import { IContentTypePublishPayload } from "../../app/models/contentTypePublish";
import { Helmet } from "react-helmet";

const PagePublishDetailPage = () => {
const { getDetail, detail, detailLoading, getGrid, publish, publishLoading } = useStore().contentTypePublishStore;
  const { contentTypeId } = useParams();
  const [schedule, setSchedule] = useState<Date | null>(null);

  const publishPage = async () => {
    let payload, status

    if (schedule) {
      status = PublishStatus.ON_SCHEDULLED 
      payload = { contentTypeValueId: contentTypeId!, schedullerTime: schedule }
    } else {
      status = PublishStatus.PUBLISHED
      payload = { contentTypeValueId: contentTypeId! } as any
    }
    
    await publish(status, payload as IContentTypePublishPayload)
      .then(res => {
        history.push('/content-types/publish')
        getGrid()
      })
  }
  
  useEffect(() => {
    if (!contentTypeId) return;

    getDetail(contentTypeId);
  }, [getDetail, contentTypeId]);

  return (
    <Box>
      <Helmet>
        <title>Content Type Publish | Nusantara Regas</title>
      </Helmet>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "content types",
            href: "/content-types/publish",
          },
          {
            label: "publish",
            href: "/content-types/publish",
          },
          {
            label: detail?.contentTypeName ?? "...",
            href: "/content-types/publish/" + contentTypeId,
          },
        ]}
        pageTitle={"Publish " + detail?.contentTypeName}
        canGoBack
      >
        <DashboardLayoutHeader.Button loading={publishLoading} onClick={publishPage} variant="contained">
          Publish
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      {detailLoading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        detail && (
          <ContentTypePublishDetailForm
            setSchedule={setSchedule}
            schedule={schedule}
            detail={detail}
          />
        )
      )}
    </Box>
  );
};

export default observer(PagePublishDetailPage);
