import { Box, Stack, TextField } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { useFormik } from "formik";
import { useStore } from "../../app/stores/store";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";

export const ChangePasswordPage = () => {
  const { changePassword } = useStore().accountStore;
  const { show } = useStore().snackbarStore;

  const { isSubmitting, handleSubmit, errors, values, handleChange } = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmNewPassword: "",
    },
    onSubmit: async (value) => {
      const { confirmNewPassword, ...payload } = value;

      await changePassword(payload);

      show("success", "Password berhasil diubah");
    },
    validationSchema: yup.object({
      oldPassword: yup.string().required(),
      password: yup.string().required(),
      confirmNewPassword: yup
        .string()
        .oneOf(
          [yup.ref("password")],
          "Konfirmasi password harus sama dengan new password"
        )
        .required(),
    }),
  });

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Dashboard",
            href: "/",
          },
          { label: "Change password", href: "#" },
        ]}
        pageTitle="Change Password"
      />
      <Stack maxWidth={500} spacing={2} component="form" onSubmit={handleSubmit}>
        <TextField
          error={!!errors.oldPassword}
          helperText={errors.oldPassword}
          value={values.oldPassword}
          type="password"
          label="Old Password"
          name={'oldPassword'}
          onChange={handleChange}
        />
        <TextField
          error={!!errors.password}
          helperText={errors.password}
          value={values.password}
          type="password"
          label="New Password"
          name={'password'}
          onChange={handleChange}
        />
        <TextField
          error={!!errors.confirmNewPassword}
          helperText={errors.confirmNewPassword}
          value={values.confirmNewPassword}
          type="password"
          label="Confirm New Password"
          name={'confirmNewPassword'}
          onChange={handleChange}
        />
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Submit</LoadingButton>
      </Stack>
    </Box>
  );
};
