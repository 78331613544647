import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useStore } from "../../app/stores/store";
import { useState } from "react";
import { IAccountInfo } from "../../app/models/account";

interface ConfirmSessionProps {
  open: boolean;
  callback: (res: IAccountInfo | undefined) => void;
}

export const ConfirmSession = ({ open, callback }: ConfirmSessionProps) => {
  const { logout, refreshToken } = useStore().accountStore
  const [isLoading, setIsLoading] = useState(false)
  
  const handleRefreshToken = async () => {
    setIsLoading(true)

    try {
      await refreshToken().then(callback)
    } finally {
      setIsLoading(false)
    }
  }
  
  return (
    <Dialog open={open}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>Sesi anda sudah habis, Apakah anda ingin melanjutkan sesi ini lagi?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={logout}>End session</Button>
        <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleRefreshToken}>Continue</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}